import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import { useWatch } from 'react-hook-form'

import css from './Checkbox.module.scss'
import { classes } from '../../../utils/classes'

export type CheckboxProps = {
  name: string
  id: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, id, onChange, onBlur }, ref) => {
    const checked = useWatch({ name })

    return (
      <div className="relative flex">
        <span
          className={classes(
            size,
            'rounded border border-black transition-all duration-300 ease-out cursor-pointer',
            css.checkbox,
            checked
              ? 'bg-black opacity-100 transform scale-100'
              : 'bg-transparent',
            checked ? css.checkboxChecked : ''
          )}
        />
        <input
          type="checkbox"
          className={classes(size, 'absolute rounded opacity-0 cursor-pointer')}
          {...{ ref, id, name, onChange, onBlur }}
        />
      </div>
    )
  }
)

const size = 'w-6 h-6'
