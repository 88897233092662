import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { captureException } from '@sentry/nextjs'
import { toast } from 'react-toastify'
import { BannerContents, BannerType } from '@nandosuk/peri-verse'

import { Card } from './Card'
import { Checkbox } from './components/inputs/Checkbox'
import { useTrackEvent } from './utils/googleTagManager'
import { useUser } from './utils/user'

export const useMarketingPreferencesCheckbox =
  (): MarketingPreferencesProps => {
    const [loading, setLoading] = useState(false)
    const [optedIn, setOptedIn] = useState<boolean | null>(null)
    const [savedOptedIn, setSavedOptedIn] = useState<boolean | null>(null)
    const trackEvent = useTrackEvent()
    const { auth_token } = useUser()

    const trackMarketingStatus = (optIn: boolean, error?: string) => {
      trackEvent(
        {
          event: optIn
            ? 'communication_preference_opt_in'
            : 'communication_preference_opt_out',
          status: error == null ? 'success' : 'failure',
          error,
        },
        {
          communication_preferences_location: 'profile_page',
        }
      )
    }

    const debounced = useDebouncedCallback(
      async (auth_token: string, optedIn: boolean) => {
        try {
          await upsertConsent(auth_token, optedIn)
          // Consent upserted
          setSavedOptedIn(optedIn)
          setLoading(false)
          trackMarketingStatus(optedIn)
          toast.success(
            <BannerContents
              title={'Your marketing preferences have been saved!'}
              type={BannerType.SUCCESS}
            />
          )
        } catch (e) {
          // Consent not upserted
          setLoading(false)
          setOptedIn(savedOptedIn) // Revert status to the last upserted one
          trackMarketingStatus(optedIn, String(e))
          toast.error(
            <BannerContents
              title={
                'There’s been a problem saving your marketing preferences. Please try again.'
              }
              type={BannerType.ERROR}
            />
          )
        }
      },
      1000
    )

    const onClick = async () => {
      if (!auth_token) {
        return
      }

      debounced.cancel()
      setOptedIn(!optedIn)
      setLoading(true)
      await debounced(auth_token, !optedIn)
    }

    useEffect(() => {
      if (auth_token) {
        getConsent(auth_token)
          .then((res) => {
            setOptedIn(res.optedIn)
            setSavedOptedIn(res.optedIn)
          })
          .catch((e) => {
            captureException(e)
          })
      }
    }, [auth_token])

    useEffect(
      () => () => {
        debounced.flush()
      },
      [debounced]
    )

    return { loading, optedIn, onClick }
  }

export type MarketingPreferencesProps = {
  loading?: boolean
  optedIn?: boolean | null
  consent?: boolean
  onClick: () => void
}

export const MarketingPreferences = ({
  loading = false,
  optedIn = false,
  consent = true,
  onClick = () => {},
}: MarketingPreferencesProps) => (
  <>
    <Card title="Marketing Preferences" onClick={onClick}>
      {typeof optedIn === 'boolean' ? (
        <Checkbox
          loading={loading}
          text="Tick the box if you're down for Extra Hot Nando's news!"
          consent={consent}
          detailText="By signing up I confirm I am aged 16 or over"
          checked={optedIn}
          onChange={() => {}}
        />
      ) : null}
    </Card>
  </>
)
async function getConsent(auth_token: string) {
  const res = await fetch('/profile/api/get-consent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      auth_token,
    }),
  })
  if (!res.ok) {
    const response = await res.text()
    throw new Error(`Bad response from profile ui api: ${response}`)
  }
  return res.json()
}

async function upsertConsent(auth_token: string, optedIn: boolean) {
  const res = await fetch('/profile/api/upsert-consent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      auth_token,
      optedIn,
    }),
  })
  if (!res.ok) {
    const response = await res.text()
    throw new Error(`Bad response from profile ui api: ${response}`)
  }
}
