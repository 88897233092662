type Options<T> = {
  returnValue?: T
  shouldReject?: boolean
}

export const timeout = <T>(
  ms: number,
  { returnValue, shouldReject }: Options<T> = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () => (shouldReject ? reject(returnValue) : resolve(returnValue!)),
      ms
    )
  })
}
