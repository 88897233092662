import { ReactNode } from 'react'
import { ErrorIcon } from '../../icons/Error'
import { classes } from '../../utils/classes'

type Props = {
  big?: boolean
  children: ReactNode
}

export const ErrorMessage = ({ big = false, children }: Props) => (
  <div
    className={classes(
      'flex items-start text-red-500',
      big ? 'p-2 bg-red-300 border-l-4 border-red-500' : 'mt-2'
    )}
  >
    <div className={classes('mt-1')}>
      <ErrorIcon large />
    </div>
    <p data-testid="error-message" className="mt-1 ml-2 text-red-700">
      {children}
    </p>
  </div>
)
