import React from 'react'
import { RenderMenu } from './RenderMenu'
import { NavDataType } from './constants'
import { classes } from '../../utils/classes'

const OPEN_CLASS = 'open'

/**
 * List component to render a collection of menu items as a list.
 *
 * @function
 * @param       {string} aria              optional aria property string for this element.
 * @param       {array}  children          child elements of the list
 * @param       {string} className         optional className
 * @param       {boolean} hideFromMobile   optional boolean for menu items that need hiding from mobile.
 * @param       {boolean} inList           true if we are already in a list, false if we are not. Controls the render of the initial ul element.
 * @param       {string} openMenu          setState state string, empty if no menu item is open, contains the menu item name if a menu item is open.
 * @param       {boolean} openMobileMenu   true if the menu is open and the opener was the mobile burger menu button
 * @param       {string} role              Aria role of the element if appropriate. Defaults to NONE if not set.
 * @param       {function} setOpenMenu     setState function to set openMenu prop.
 * @param       {function} setOpenMobileMenu setState function to set openMobileMenu prop
 * @param       {string} text              Name of the menu item to which this list is related. Used to set open.
 * @constructor
 */

export type ListProps = {
  aria?: string
  children: Array<NavDataType>
  className?: string
  hideFromMobile?: boolean
  inList?: boolean
  menu?: string
  openMenu?: string
  openMobileMenu?: boolean
  role: string
  setOpenMenu?: (value: string) => void
  setOpenMobileMenu?: () => void
  text: string
}

export const List = ({
  aria,
  children,
  className,
  hideFromMobile,
  inList,
  openMenu,
  openMobileMenu,
  role,
  setOpenMenu,
  setOpenMobileMenu,
  text,
}: ListProps) => {
  const ourClassName = classes(
    className ? className : '',
    openMenu === text ? OPEN_CLASS : '',
    hideFromMobile && openMobileMenu ? OPEN_CLASS : ''
  )

  const renderMenuProps = {
    inList: true,
    openMenu,
    setOpenMenu,
    openMobileMenu,
    setOpenMobileMenu,
    role,
  }

  if (children && children.length) {
    if (inList) {
      return (
        <li role={role || 'none'}>
          <ul className={ourClassName}>
            <RenderMenu {...renderMenuProps}>{children}</RenderMenu>
          </ul>
        </li>
      )
    }
    return (
      <ul aria-label={aria} className={ourClassName} role={role}>
        <RenderMenu {...renderMenuProps}>{children}</RenderMenu>
      </ul>
    )
  }

  return null
}
