import React, { useState, useEffect } from 'react'
import { navDataUk } from './navDataUk'
import { navDataIe } from './navDataIe'
import { footerDataUk } from './footerDataUk'
import { footerDataIe } from './footerDataIe'
import { Menu } from './Menu'
import { useCountry } from '../../utils/country'
React.useLayoutEffect = React.useEffect
/**
 * This component builds the main site navigation and additionally the footer site navigation. It is currently driven
 * by data contained in both topMenuData.js and footerData.js, with the idea that this will eventually come from
 * the CMS via GraphQL. The data describes objects with a 'type' property which refers to one of the sub components in
 * this folder and other properties specific to the component. Most have the ability to set a className and almost all
 * have a 'children' property which is an optional array of more menu items.
 *
 * @function
 * @param       {string} style    either 'main' or 'footer' depending on the position of this component.
 * @param       {function} setShade state Function called with parameters to show or hide the shade component.
 * @constructor
 */

export type SiteNavProps = {
  style: 'main' | 'footer'
  shade?: {
    opener: string
    shade: boolean
    menu?: boolean
    filters?: boolean
    onClick?: () => void
  }
  setShade?: (options: object) => void
}

export const SiteNav = ({ style, shade, setShade }: SiteNavProps) => {
  const [openMenu, setOpenMenu] = useState<string>('')
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false)

  const country = useCountry()
  const navData = country === 'GB' ? navDataUk : navDataIe
  const footerData = country === 'GB' ? footerDataUk : footerDataIe

  // Show or hide the shade depending if any menu sub navigations items are shown.
  useEffect(() => {
    if (
      typeof setShade !== 'function' ||
      typeof openMenu !== 'string' ||
      openMobileMenu
    ) {
      return
    }

    if (openMenu && openMenu !== '') {
      setShade({
        opener: 'SiteNav',
        shade: true,
        menu: true,
        onClick: () => setOpenMenu && setOpenMenu(''),
      })
    } else if (
      openMenu === '' &&
      (shade?.shade || shade?.opener !== 'SiteNav')
    ) {
      setShade({ closer: 'SiteNav', shade: false })
    }
  }, [openMenu])

  const menuProps = {
    openMenu,
    setOpenMenu,
    openMobileMenu,
    setOpenMobileMenu,
  }

  const mainMenuProps = {
    ...menuProps,
    ...navData,
  }

  const footerMenuProps = {
    ...menuProps,
    ...footerData,
  }

  return style && style === 'main' ? (
    <header className="header">
      <Menu {...mainMenuProps} />
    </header>
  ) : (
    <Menu {...footerMenuProps} />
  )
}
