import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { ErrorMessage, ErrorMessageSpacer } from '../ErrorMessage'
import { Label } from '../Label'
import { TextArea } from '../inputs/TextArea'

export type TextAreaFieldProps = {
  label: string
  name: string
  error?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
}

export const TextAreaField = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(({ label, name, error, placeholder, onChange, onBlur }, ref) => {
  const id = `field-${name}`

  return (
    <>
      <Label forId={id}>{label}</Label>
      <TextArea
        invalid={Boolean(error)}
        {...{
          ref,
          id,
          placeholder,
          name,
          onChange,
          onBlur,
        }}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : <ErrorMessageSpacer />}
    </>
  )
})
