import React, {
  ComponentType,
  ChangeEventHandler,
  FocusEventHandler,
} from 'react'

import { Radio } from './Radio'

type CountryRadioProps = {
  flag: ComponentType<{}>
  countryName: string
  value: string
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const CountryRadio = React.forwardRef<
  HTMLInputElement,
  CountryRadioProps
>(({ flag: Flag, countryName, name, value, onChange, onBlur }, ref) => (
  <label className="flex items-center mr-8">
    <Flag />
    <div className="flex items-center mx-2">
      <Radio {...{ name, value, ref, onChange, onBlur }} />
    </div>
    <abbr title={countryName} className="!no-underline">
      {value}
    </abbr>
  </label>
))
