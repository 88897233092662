import React from 'react'

export const Alert = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-current"
  >
    <circle cx="12" cy="12" r="12" fill="current" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.74 4.5H13.26C13.4032 4.5 13.5 4.58201 13.5 4.70614L12.9477 14.2939C12.9477 14.418 12.8523 14.5 12.7077 14.5H11.2923C11.1477 14.5 11.0523 14.418 11.0523 14.2939L10.5 4.70614C10.5 4.58201 10.5955 4.5 10.74 4.5ZM10.7125 16.5H13.2875C13.4046 16.5 13.4986 16.5938 13.5 16.709V19.291C13.5 19.4062 13.4046 19.5 13.2875 19.5H10.7125C10.5954 19.5 10.5 19.4062 10.5 19.291V16.709C10.5 16.5938 10.5954 16.5 10.7125 16.5Z"
      fill="white"
    />
  </svg>
)
