import { createContext, useEffect, useState } from 'react'
import OktaAuth, {
  AuthState,
  toRelativeUrl,
  generateState,
} from '@okta/okta-auth-js'
import { useOnClientSideOnly } from '../utils/client_side'
import { useOrigin } from '../utils/origin'
import { oktaConfigCiam, newAuthClient } from '../utils/oktaAuth'
import OktaContext, { useOktaAuth } from './OktaContext'

type AuthProps = {
  children: React.FunctionComponent<{ auth_token: string }>
}

export const SignOutContext = createContext(async () => {})

export const AuthProvider = ({ children: render }: AuthProps) => {
  const origin = useOrigin()
  const oktaAuth = useOnClientSideOnly(() =>
    newAuthClient(origin, oktaConfigCiam(origin))
  )
  const [authState, setAuthState] = useState<AuthState | null>(null)

  useEffect(() => {
    const authStateHandler = (authState: AuthState) => {
      setAuthState(authState)
    }
    const startOktaService = async (oktaAuth: OktaAuth) => {
      // Update OktaContext provider with latest authState
      const currentAuthState = oktaAuth.authStateManager.getAuthState()
      if (currentAuthState !== authState) {
        setAuthState(currentAuthState)
      }

      oktaAuth.authStateManager.subscribe(authStateHandler)

      await oktaAuth.start()

      if (!(await oktaAuth.isAuthenticated())) {
        const state = generateState()
        oktaAuth.setOriginalUri(
          toRelativeUrl(window.location.href, window.location.origin),
          state
        )
        oktaAuth.signInWithRedirect({ state })
      }
    }
    if (oktaAuth) {
      startOktaService(oktaAuth)
      return () => {
        oktaAuth.authStateManager.unsubscribe(authStateHandler)
      }
    }
  }, [oktaAuth])

  if (!oktaAuth || !authState) {
    return null
  }

  const sign_out = async () => {
    await oktaAuth.signOut()
  }

  return (
    <OktaContext.Provider value={{ oktaAuth, authState }}>
      <SignOutContext.Provider value={sign_out}>
        <PkceAuthConsumer render={render} />
      </SignOutContext.Provider>
    </OktaContext.Provider>
  )
}

const PkceAuthConsumer = ({
  render,
}: {
  render: React.FunctionComponent<{ auth_token: string }>
}) => {
  const { authState } = useOktaAuth()
  const auth_token = authState?.accessToken?.accessToken || null
  return auth_token ? render({ auth_token }) : null
}
