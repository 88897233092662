import React, { useCallback, useState } from 'react'
import {
  BaseModalProps,
  Modal,
  ModalBodyAsLoader,
  ModalBodyWithTitle,
} from './components/Modal'
import { Factor } from './utils/mfa'
import { Button } from './components/buttons/Button'
import { FormState, useFormState } from './utils/useFormState'
import { P } from './components/typography'
import { ErrorMessage } from './components/form/ErrorMessage'
import { loadedAnimationDuration } from './components/Loader'
import { timeout } from './utils/timeout'
import { AreYouSure } from './icons/AreYouSure'
import NoMFA from './icons/NoMFA'

interface FactorDeleteModalProps extends BaseModalProps {
  factor: Factor
  noOtherFactors: boolean
  onDelete: () => Promise<void>
}

const FactorDeleteModal = React.memo(
  ({
    onClose,
    onDelete,
    factor,
    noOtherFactors,
    isOpen,
    ...props
  }: FactorDeleteModalProps) => {
    const { factorType } = factor
    const [state, setState] = useFormState()
    const [submissionError, setError] = useState('')
    const onSubmit = useCallback(async () => {
      setState(FormState.Submitting)
      setError('')
      try {
        await onDelete()
      } catch (e) {
        console.error('Submission error:', e)

        setError('There was an error. Please try again.')
        setState(FormState.AwaitingSubmission)
        return
      }
      setState(FormState.Submitted)
      await timeout(loadedAnimationDuration)
      closeAndReset()
    }, [onDelete])
    const closeAndReset = useCallback(async () => {
      onClose()
      await timeout(1 * 1000)
      setState(FormState.AwaitingSubmission)
      setError('')
    }, [onClose])
    return (
      <Modal isOpen={isOpen} onClose={closeAndReset} {...props}>
        {state === FormState.AwaitingSubmission ? (
          <ModalBodyWithTitle
            title={
              factorType === 'sms' ? 'Remove text message' : 'Remove Email'
            }
            showCloseButton
          >
            {submissionError ? (
              <div className="mb-4">
                <ErrorMessage big>{submissionError}</ErrorMessage>
              </div>
            ) : null}
            <div className="mb-4">
              {noOtherFactors ? <NoMFA /> : <AreYouSure />}
            </div>
            <P className="mb-4 text-center">
              {noOtherFactors
                ? 'If you remove this, you leave your account only protected by password and more vulnerable, we advise you have at least one type of security enabled.'
                : factorType === 'sms'
                ? 'It helps keep your account safe. If you change your mind, you can re-enable text message later.'
                : 'It helps keep your account safe. If you change your mind, you can re-enable email later.'}
            </P>
            <Button type="button" onClick={onSubmit}>
              {factorType === 'sms' ? 'Yes, remove text' : 'Yes, remove email'}
            </Button>
            <Button
              type="button"
              secondary
              secondaryClasses="bg-transparent text-blueButton text-sm"
              onClick={closeAndReset}
              upperCase={false}
            >
              Cancel
            </Button>
          </ModalBodyWithTitle>
        ) : (
          <ModalBodyAsLoader loaded={state === FormState.Submitted} />
        )}
      </Modal>
    )
  }
)

export default FactorDeleteModal
