import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BaseModalProps,
  Modal,
  ModalBodyAsLoader,
  ModalBodyWithTitle,
} from './components/Modal'
import { EmailFactor } from './utils/mfa'
import { TextField } from './components/form/fields/TextField'
import { Button } from './components/buttons/Button'
import { useForm } from 'react-hook-form'
import { FormState, useFormState } from './utils/useFormState'
import { P } from './components/typography'
import { ErrorMessage } from './components/form/ErrorMessage'
import { loadedAnimationDuration } from './components/Loader'
import { timeout } from './utils/timeout'
import ResendCode from './ResendCode'

interface EmailFactorEnrolModalProps extends BaseModalProps {
  factor: EmailFactor
  onEnrol: () => Promise<EmailFactor | undefined>
  onActivate: (factorId: string, passCode: string) => Promise<void>
}

type FormInputs = {
  passCode: string
}

const EmailFactorEnrolModal = React.memo(
  ({
    onClose,
    factor,
    onEnrol,
    onActivate,
    isOpen,
    ...props
  }: EmailFactorEnrolModalProps) => {
    const [resendCountdown, setResendCountdown] = useState<number | null>(null)
    const [state, setState] = useFormState()
    const [submissionError, setError] = useState('')
    const submitRef = useRef<HTMLInputElement>(null)
    const submitForm = useCallback(() => {
      submitRef?.current?.click()
    }, [submitRef])
    const activate = useCallback(
      async (data: FormData) => {
        setState(FormState.Submitting)
        setError('')
        const passCode = data.get('passCode') as string
        try {
          await onActivate(factor.id, passCode)
        } catch (e) {
          console.error('Submission error:', e)

          setError('Please enter the correct 6 digit code and try again.')
          setState(FormState.AwaitingSubmission)
          return
        }
        setState(FormState.Submitted)
        await timeout(loadedAnimationDuration)
        closeAndReset()
      },
      [onActivate, onClose, factor]
    )
    const closeAndReset = useCallback(async () => {
      onClose()
      await timeout(1 * 1000)
      setState(FormState.AwaitingSubmission)
      setResendCountdown(null)
      setError('')
    }, [onClose])
    const enrol = useCallback(async () => {
      setState(FormState.Submitting)
      setError('')
      try {
        await onEnrol()
      } catch (e) {
        console.error('Submission error:', e)
        setError(
          'There was an error sending you the code. Please wait for 30 seconds and click "Re-send Code".'
        )
      }
      setState(FormState.AwaitingSubmission)
      setResendCountdown(30)
    }, [onEnrol])
    useEffect(() => {
      if (isOpen) {
        enrol()
      }
    }, [isOpen])
    useEffect(() => {
      const interval = setInterval(() => {
        if (resendCountdown === null) return
        if (resendCountdown > 0) {
          setResendCountdown(resendCountdown - 1)
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [resendCountdown])
    return (
      <Modal isOpen={isOpen} onClose={closeAndReset} {...props}>
        {state === FormState.AwaitingSubmission ? (
          <ModalBodyWithTitle title="Enable Email" showCloseButton>
            {submissionError ? (
              <div className="mb-4">
                <ErrorMessage big>{submissionError}</ErrorMessage>
              </div>
            ) : null}
            <P className="mb-4">
              We’ve just sent a six-digit verification code to your inbox.
            </P>
            <EmailFactorEnrolForm submitRef={submitRef} onSubmit={activate} />
            {resendCountdown !== null ? (
              <ResendCode countdown={resendCountdown} onClick={enrol} />
            ) : null}
            <Button type="button" onClick={submitForm}>
              Finish
            </Button>
            <Button
              type="button"
              secondary
              secondaryClasses="bg-transparent text-blueButton text-sm"
              onClick={closeAndReset}
              upperCase={false}
            >
              Cancel
            </Button>
          </ModalBodyWithTitle>
        ) : (
          <ModalBodyAsLoader loaded={state === FormState.Submitted} />
        )}
      </Modal>
    )
  }
)

const EmailFactorEnrolForm = ({
  submitRef,
  onSubmit,
}: {
  submitRef: React.RefObject<HTMLInputElement>
  onSubmit: (data: FormData) => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      passCode: '',
    },
  })
  return (
    <form
      className="flex flex-col w-full"
      onSubmit={handleSubmit((data) => {
        const formData = new FormData()

        Object.entries(data).forEach(([key, value]) => {
          formData.set(key, value)
        })

        onSubmit(formData)
      })}
    >
      <TextField
        label="Enter it here"
        error={errors.passCode ? 'Please enter a valid 6 digit code' : ''}
        {...register('passCode', {
          required: true,
          pattern: /^[0-9]{6}$/,
        })}
      />
      <input type="submit" ref={submitRef} hidden />
    </form>
  )
}

export default EmailFactorEnrolModal
