import { Country } from '../utils/country'

export enum DiscountType {
  Student = 'student',
  Nhs = 'nhs',
  Hse = 'hse',
}

interface DiscountVerificationApiResponse {
  status: number
}

export interface EmailValidationSuggestionParams {
  name: string
  website: string
  url: string
  country: Country
  submitted_by_discount_email: string
  extra: EmailValidationExtra
}

type EmailValidationExtra =
  | {}
  | {
      school_start_year?: string
      school_end_year?: string
    }

export interface DiscountTypeConfig {
  type: DiscountType
  allow_suggestion: boolean
  disabled: boolean
  expiry_days: number
  validation_type: 'email'
  validation_settings: {
    [key: string]: string
  }
  extra?: {
    how_it_works?: {
      title: string
      items: string[]
    }
    just_so_you_know?: {
      title: string
      items: string[]
    }
  }
  created_at: string
  updated_at: string
}

export async function checkEmailValidation(
  verifyApiUrl: string,
  email: string,
  discountType: DiscountType
): Promise<DiscountVerificationApiResponse> {
  const res = await fetch(
    `${verifyApiUrl}/type/${discountType}/validation/email/check?email=${email}`,
    {
      method: 'GET',
    }
  )
  if (res.status != 204) {
    const errorMessage = await res.text()
    throw new Error(
      `Error response from verification api (${res.status}): ${errorMessage}`
    )
  }
  return { status: res.status }
}

export async function suggestEmailValidation(
  verifyApiUrl: string,
  verifyApiToken: string,
  discountType: DiscountType,
  params: EmailValidationSuggestionParams
): Promise<DiscountVerificationApiResponse> {
  const res = await fetch(
    `${verifyApiUrl}/type/${discountType}/validation/email/suggest`,
    {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${verifyApiToken}`,
      }),
      body: JSON.stringify(params),
    }
  )
  if (res.status != 204) {
    const errorMessage = await res.text()
    throw new Error(
      `Error response from verification api (${res.status}): ${errorMessage}`
    )
  }
  return { status: res.status }
}

export async function suggestEmailValidationCallback(
  verifyApiUrl: string,
  discountType: DiscountType,
  token: string
): Promise<DiscountVerificationApiResponse> {
  const res = await fetch(
    `${verifyApiUrl}/type/${discountType}/validation/email/suggest/callback?` +
      new URLSearchParams({ token }).toString(),
    {
      method: 'GET',
    }
  )
  if (res.status != 204) {
    const errorMessage = await res.text()
    throw new Error(
      `Error response from verification api (${res.status}): ${errorMessage}`
    )
  }
  return { status: res.status }
}

export async function getDiscountTypeConfig(
  verifyApiUrl: string,
  discountType: DiscountType
): Promise<DiscountTypeConfig> {
  const res = await fetch(`${verifyApiUrl}/type/${discountType}`, {
    method: 'GET',
  })
  if (res.status != 200) {
    const errorMessage = await res.text()
    throw new Error(
      `Error response from verification api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}
