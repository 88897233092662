import React, { ReactNode } from 'react'
import NextLink from 'next/link'

import { classes } from '../../utils/classes'

const linkClassNames =
  'inline-block text-sm font-medium cursor-pointer text-blue hover:underline group-hover:underline focus:underline focus:text-black group-focus:underline group-focus:text-black active:underline active:text-black'
const buttonClassNames =
  'flex justify-center items-center w-full font-sans text-center uppercase rounded-sm h-11 bg-black text-white'

export type InternalLinkProps = {
  children: ReactNode
  path: string
  className?: string
  appearance?: 'link' | 'button'
}

export const InternalLink = ({
  children = '',
  path = '',
  className = '',
  appearance = 'link',
}: InternalLinkProps) => (
  <NextLink href={path} legacyBehavior>
    <a
      className={classes(
        appearance === 'link' ? linkClassNames : buttonClassNames,
        className
      )}
    >
      {children}
    </a>
  </NextLink>
)

export type LinkProps = {
  children: ReactNode
  url?: string
  newTab?: boolean
  className?: string
}

export const Link = ({
  children = '',
  url = '',
  className = '',
  newTab = false,
}: LinkProps) => {
  return !!url ? (
    <a
      data-testid="link"
      className={classes(linkClassNames, className)}
      href={url}
      target={newTab ? '_blank' : ''}
    >
      {children}
    </a>
  ) : (
    <span className={linkClassNames}>{children}</span>
  )
}

export type ButtonLinkProps = {
  children: ReactNode
  className: string
  onClick: () => void
  underlineLinks?: boolean
}

export const ButtonLink = ({
  children = '',
  className = '',
  onClick = () => {},
  underlineLinks = false,
}: ButtonLinkProps) => (
  <button
    type="button"
    className={classes(
      linkClassNames,
      underlineLinks ? 'underline hover:text-black' : '',
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
)
