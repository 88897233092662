import { Dispatch, SetStateAction, useState } from 'react'

export enum FormState {
  AwaitingSubmission,
  Submitting,
  Submitted,
}

export type SetFormState = Dispatch<SetStateAction<FormState>>

export const useFormState = (): [FormState, SetFormState] => {
  const [state, setState] = useState<FormState>(FormState.AwaitingSubmission)

  return [state, setState]
}
