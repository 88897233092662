import {
  getFactors,
  deleteFactor,
  enrolFactor,
  activateFactor,
} from '../api/auth'
import { User } from '../User'
import { Country } from './country'

export type FactorType = 'email' | 'sms'
export type FactorStatus = 'ACTIVE' | 'PENDING_ACTIVATION'

export interface EnrolledFactor {
  factorType: FactorType
  id: string
  status: FactorStatus
  email?: string
  phoneNumber?: string
}

export interface SupportedFactor {
  factorType: FactorType
}

export interface Factor {
  enrolled: boolean
  supported: boolean
  id: string
  factorType: FactorType
  status?: FactorStatus
}

export interface EmailFactor extends Factor {
  factorType: 'email'
  email?: string
}

export interface SMSFactor extends Factor {
  factorType: 'sms'
  phoneNumber?: string
  country?: Country
}

export interface FactorList {
  emailFactor: EmailFactor
  smsFactor: SMSFactor
}

export const getDefaultEmailFactor = (user: User): EmailFactor => {
  return {
    enrolled: false,
    supported: false,
    factorType: 'email',
    email: user.email,
    id: '',
  }
}

export const getDefaultSMSFactor = (user: User): SMSFactor => {
  return {
    enrolled: false,
    supported: false,
    factorType: 'sms',
    phoneNumber: user.mobileNumber,
    country: user.country,
    id: '',
  }
}

export const getFactorList = async (
  authApiUrl: string,
  auth_token: string | null,
  user: User
) => {
  const list: FactorList = {
    emailFactor: getDefaultEmailFactor(user),
    smsFactor: getDefaultSMSFactor(user),
  }

  if (!auth_token) {
    return list
  }

  const { enrolledFactors, supportedFactors } = await getFactors(
    authApiUrl,
    auth_token
  )

  enrolledFactors.forEach((factor) => {
    const { id, status, email, phoneNumber, factorType } = factor
    if (factorType === 'email') {
      list.emailFactor.enrolled = true
      list.emailFactor.id = id
      list.emailFactor.email = email
      list.emailFactor.status = status
    } else if (factorType === 'sms') {
      list.smsFactor.enrolled = true
      list.smsFactor.id = id
      list.smsFactor.phoneNumber = phoneNumber
      list.smsFactor.status = status
    }
  })
  supportedFactors.forEach((factor) => {
    const { factorType } = factor
    if (factorType === 'email') {
      list.emailFactor.supported = true
    } else if (factorType === 'sms') {
      list.smsFactor.supported = true
    }
  })
  return list
}

export const deleteFactorAndUpdate = async (
  authApiUrl: string,
  auth_token: string | null,
  user: User,
  factorId: string
) => {
  if (!auth_token) return
  await deleteFactor(authApiUrl, auth_token, factorId)
  return await getFactorList(authApiUrl, auth_token, user)
}

export const enrolEmailFactor = async (
  authApiUrl: string,
  auth_token: string | null,
  emailFactor: EmailFactor
): Promise<EmailFactor | undefined> => {
  if (!auth_token) return
  const factor = await enrolFactor(authApiUrl, auth_token, 'email')
  return {
    ...emailFactor,
    enrolled: true,
    email: factor.email,
    status: factor.status,
    id: factor.id,
  }
}

export const enrolSMSFactor = async (
  authApiUrl: string,
  auth_token: string | null,
  smsFactor: SMSFactor,
  phoneNumber: string
): Promise<SMSFactor | undefined> => {
  if (!auth_token) return
  if (smsFactor.status === 'ACTIVE' && smsFactor.id) {
    await deleteFactor(authApiUrl, auth_token, smsFactor.id)
  }
  const factor = await enrolFactor(authApiUrl, auth_token, 'sms', phoneNumber)
  return {
    ...smsFactor,
    enrolled: true,
    phoneNumber: factor.phoneNumber,
    status: factor.status,
    id: factor.id,
  }
}

export const activateFactorAndUpdate = async (
  authApiUrl: string,
  auth_token: string | null,
  user: User,
  factorId: string,
  passCode: string
) => {
  if (!auth_token) return
  await activateFactor(authApiUrl, auth_token, factorId, passCode)
  return await getFactorList(authApiUrl, auth_token, user)
}
