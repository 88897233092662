import React, { useState } from 'react'
import { DateTime } from 'luxon'

import { Card } from './Card'
import { Title, P } from './components/typography'
import { Job, JobType } from './User'
import { timeout } from './utils/timeout'
import { dateFormat } from './pages/api/add-discount'
import { WarningMessage } from './components/inputs/WarningMessage'
import { InternalLink, Link } from './components/typography/Link'

type JobCardDisabledProps = {
  job: Job
}

export const JobCardDisabled = React.memo(({ job }: JobCardDisabledProps) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-lg py-6 sm:p-6 md:w-full md:max-w-none">
        <Card withPadding={false}>
          <JobCardBodyDisabled job={job} />
        </Card>
      </div>
    </div>
  )
})

export const JobCardBodyDisabled = ({ job }: JobCardDisabledProps) => {
  const expiryDate = DateTime.fromISO(job.expiry)

  const expired = job.disabled || expiryDate < DateTime.now()

  return (
    <div className="p-4 relative md:px-20">
      <div className="flex flex-col items-center text-center">
        <WarningMessage>
          Our student discount's come to an end. For more info, check the{' '}
          <Link
            url="/nandos-terms-and-conditions#studentdiscount"
            newTab={true}
          >
            T&C's
          </Link>
          .
        </WarningMessage>
        <Title
          size="card"
          className="mb-4 max-w-sm text-center leading-tight mt-4"
        >
          Just to let you know…
        </Title>

        <P className="max-w-sm text-xl">
          {expired
            ? 'Your 20% student discount has now expired.'
            : "You're still verified as a student - there's just no live discount RN."}
        </P>

        {expired ? (
          <div className="w-full max-w-sm my-4">
            <InternalLink path={discountPath(job.type)} appearance="button">
              Join waitlist
            </InternalLink>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const discountPath = (jobType: JobType): string => {
  switch (jobType) {
    case 'PARTNER':
      return '/discount/nandoca'
    case 'NHS':
      return '/benefits/nhs'
    case 'HSE':
      return '/benefits/hse'
    case 'STUDENT':
      return '/benefits/student'
    default:
      return '/discount'
  }
}
