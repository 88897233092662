import React, { ReactNode } from 'react'

import { classes } from '../../utils/classes'
import css from './Button.module.scss'

export type ButtonProps =
  | {
      type: 'link'
      children: ReactNode
      href: string
      accent?: boolean
      secondary?: boolean
      primaryClasses?: string
      secondaryClasses?: string
      upperCase?: boolean
      testId?: string
    }
  | {
      type: 'submit' | 'button'
      children: ReactNode
      onClick?: () => void
      accent?: boolean
      secondary?: boolean
      primaryClasses?: string
      secondaryClasses?: string
      upperCase?: boolean
      testId?: string
    }
const baseClasses = 'w-full font-sans text-center rounded-sm h-11'

const defaultPrimaryClasses = 'bg-black text-white'
const defaultSecondaryClasses = 'bg-white text-black border border-black'

export const Button = (props: ButtonProps) => {
  const primaryClasses = props.primaryClasses || defaultPrimaryClasses
  const secondaryClasses = props.secondaryClasses || defaultSecondaryClasses
  const uppercase =
    typeof props.upperCase === 'boolean' ? props.upperCase : true
  if (props.type === 'link') {
    const { accent, href, children, secondary, testId } = props

    return (
      <a
        href={href}
        className={classes(
          baseClasses,
          'flex justify-center items-center',
          secondary ? secondaryClasses : primaryClasses,
          accent ? css.button : '',
          uppercase ? 'uppercase' : ''
        )}
        data-testid={testId || ''}
      >
        {children}
      </a>
    )
  }

  const { accent, type, onClick, children, secondary, testId } = props

  return (
    <button
      type={type}
      data-testid={testId || 'input'}
      className={classes(
        baseClasses,
        secondary ? secondaryClasses : primaryClasses,
        accent ? css.button : '',
        uppercase ? 'uppercase' : ''
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
