import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { defaultUser, User } from '../User'
import { fetchEntity } from '../api/entity'
import { graphqlUrl } from './graphqlClient'

type UserContextType = {
  user: User
  setUser: Dispatch<SetStateAction<User>>
  auth_token: string | null
  error: Error | null
  loading: boolean
}

const UserContext = createContext<UserContextType>({
  user: defaultUser,
  setUser: () => {},
  auth_token: null,
  error: null,
  loading: false,
})

type UserProviderProps = {
  auth_token: string | null
  children: ReactNode
}

export const UserProvider = ({ auth_token, children }: UserProviderProps) => {
  const apiUrl = graphqlUrl(process.env.NEXT_PUBLIC_PROFILE_API_V2_URL || '')

  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User>(defaultUser)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const getData = async () => {
      if (!auth_token) return

      try {
        const user = await fetchEntity(apiUrl, auth_token)
        setUser(user)
      } catch (err) {
        console.error(err)
        setError(err)
      }
      setLoading(false)
    }

    getData()
  }, [apiUrl, auth_token])

  return (
    <UserContext.Provider value={{ user, setUser, auth_token, error, loading }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const user = useContext(UserContext)
  return user
}
