import { Label } from '../Label'
import { PhoneInput } from './PhoneInput'

export type PhoneNumberProps = {
  name: string
  label?: string
  defaultCountry: string
  defaultValue: string
  errorMessage?: string
  invalid?: boolean
  error?: boolean
  onChange?: (value: any) => void
}

export const PhoneNumber = ({
  name,
  label = 'Phone number',
  defaultCountry,
  defaultValue,
  errorMessage,
  invalid,
  onChange,
}: PhoneNumberProps) => {
  const id = 'phonenumber'

  return (
    <div className="mb-6">
      <Label forId={id} required={false}>
        {label}
      </Label>
      <div id={id}>
        <PhoneInput
          name={name}
          label={label}
          defaultCountryCode={(defaultCountry || 'GB').toUpperCase()}
          defaultValue={defaultValue}
          errorMessage={errorMessage}
          invalid={invalid}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
