import React, { useEffect, useState } from 'react'
/**
 * Shade component. Always present, CSS hides it behind the main page and makes it unclickable.
 *
 * @function
 * @param       {object} shade    useState object controlling the shade
 * @param       {function} setShade useState function to set the shade object to control the shade.
 * @constructor
 */
export type ShadeProps = {
  shade: {
    opener: string
    shade: boolean
    menu: boolean
    onClick: () => void
    filters: boolean
  }
  setShade: (options: object) => void
}

export const Shade = ({ shade, setShade }: ShadeProps) => {
  const [open, setOpen] = useState(false)

  const html =
    typeof document === 'undefined' ? null : document.querySelector('html')
  /**
   * Sets classes on the HTML element for shading.
   *
   * @function
   * @param    {boolean} filters if true we set an additional filters class which changes the z-index of the shade so it
   *                              appears under the header but on top of the main content.
   * @param    {boolean} menu    if true we set an additional 'mainmenu' class which changes the z-index of the shade so
   *                              it appears under the main menu.
   */
  const shadeMe = (filters: boolean, menu: boolean): void => {
    html?.classList.add('shaded')
    if (filters) {
      html?.classList.add('filters')
    }
    if (menu) {
      html?.classList.add('mainmenu')
    }
  }
  /**
   * Remove all shade related html tag classes.
   *
   * @function
   * @returns  {[type]} [description]
   */
  const unshadeMe = (): void => {
    html?.classList.remove('shaded')
    setTimeout(() => {
      html?.classList.remove('filters')
      html?.classList.remove('mainmenu')
    }, 250)
  }

  /**
   * Handle click on shade. Usually this will close the shade, but a custom function can be passed in to do something
   * different.
   *
   * @function
   * @param    {Event} e  original click/touch event.
   * @returns  {null}
   */
  function handleClick(e: React.MouseEvent<Element, MouseEvent>): void {
    e.preventDefault()
    e.stopPropagation()
    if (typeof shade !== 'object' || typeof setShade !== 'function') {
      return
    }

    if (typeof shade.onClick !== 'function') {
      setShade({ closer: 'Shade', shade: false })
    } else {
      shade.onClick()
    }
    return
  }

  useEffect(() => {
    if (typeof shade !== 'object') {
      return
    }

    if (shade.shade) {
      if (!open) {
        //only do stuff if the shade isn't already open.
        setOpen(true)
        shadeMe(shade.filters, shade.menu)
      }
    } else {
      if (open) {
        //only do stuff if the shade is already open.
        setOpen(false)
        unshadeMe()
      }
    }
  }, [shade.shade])

  return <div className="shade-component" onClick={handleClick} />
}
