import type { GetServerSideProps } from 'next'

import { getURL } from '../utils/url'
import { Authenticated } from '../components/Authenticated'
import { HomePage } from '../HomePage'

type Props = {
  host: string
  origin: string
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  const { host, origin } = getURL(context.req)
  return {
    props: {
      host,
      origin,
    },
  }
}

export default function Home({ host, origin }: Props) {
  return (
    <Authenticated host={host} origin={origin}>
      <HomePage />
    </Authenticated>
  )
}
