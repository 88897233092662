import React from 'react'
import { Divider } from './Divider'
import { Link } from './Link'
import { List } from './List'
import { LogoLink } from './LogoLink'
import { MobileNavigation } from './MobileNavigation'
import { RestaurantLocator } from './RestaurantLocator'
import { Title } from './Title'
import { NavDataType } from './constants'
React.useLayoutEffect = React.useEffect

type MenuRendererType = {
  [name: string]: React.FC<any>
}

const menuRenderer: MenuRendererType = {
  MobileNavigation,
  Divider,
  Link,
  List,
  RestaurantLocator,
  LogoLink,
  Title,
}

/**
 * "meta" component that loads in the correct component according to the data in the JS data objects.
 * TODO: investigate. There might be a better way to do this ;)
 *
 * @function
 * @param       {object} props react props to be passed down to the child components. Descriptions of them exist in the sub-components.
 * @constructor
 */

export type RenderMenuProps = {
  children: Array<NavDataType>
  inList?: boolean
  openMenu?: string
  setOpenMenu?: (value: string) => void
  openMobileMenu?: boolean
  setOpenMobileMenu?: (value: boolean) => void
  aria?: string
  role: string
}

export const RenderMenu = ({
  children,
  inList,
  openMenu,
  setOpenMenu,
  openMobileMenu,
  setOpenMobileMenu,
  aria,
  role,
}: RenderMenuProps) => {
  if (!children || !children.length) {
    return null
  }

  return (
    <>
      {children.map((menuItem, idx) => {
        if (menuRenderer[menuItem.type]) {
          return React.createElement(menuRenderer[menuItem.type], {
            key: 'unique' + idx,
            ...menuItem,
            inList,
            openMenu,
            setOpenMenu,
            openMobileMenu,
            setOpenMobileMenu,
            aria,
            role,
          })
        }

        return null
      })}
    </>
  )
}
