import type { AppProps } from 'next/app'
import Script from 'next/script'

import { GoogleTagManager } from '../utils/googleTagManager'
import { ConvertExperiments } from '../utils/convertExperiments'
import { Slide, ToastContainer } from 'react-toastify'
import Head from 'next/dist/shared/lib/head'

import 'tailwindcss/tailwind.css'
import '../components/footer/Footer.scss'
import '../components/sitenav/Navbar.scss'
import '../components/shade/Shade.scss'
import '../components/Modal.scss'
import '../break-words.css'
import '@nandosuk/peri-verse/dist/index.css'
import 'react-toastify/dist/ReactToastify.css'
import '../toast.css'

const disableAnalytics = process.env.NEXT_PUBLIC_DISABLE_ANALYTICS === 'true'

interface MainProps extends AppProps {
  err: Error
}

function App({ Component, pageProps, err }: MainProps) {
  return (
    <>
      <Head>
        <link
          rel="preconnect"
          href={process.env.NEXT_PUBLIC_CDN_ASSETS_URL + '/fonts/font-face.css'}
        />
        <link
          href={process.env.NEXT_PUBLIC_CDN_ASSETS_URL + '/fonts/font-face.css'}
          rel="stylesheet"
        />
      </Head>

      <div id="consent_blackbar" />
      <Component {...pageProps} err={err} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        transition={Slide}
        icon={false}
      />

      <Script
        async
        src="https://consent.trustarc.com/notice?domain=nandos.co.uk&country=gb&js=nj&c=teconsent&noticeType=bb&gtm=1&text=true"
        crossOrigin=""
        strategy="beforeInteractive"
      />

      {disableAnalytics ? null : (
        <>
          <script dangerouslySetInnerHTML={{ __html: hotjar }} />
          <GoogleTagManager id="GTM-KHMP5FJ" />
          <ConvertExperiments id="10021747-10021785" />
        </>
      )}
    </>
  )
}

const hotjar = `
<!-- Hotjar Tracking Code for https://www.nandos.co.uk -->
(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:781721,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`

export default App
