import { useEffect, useState } from 'react'

export function usePrefersReducedMotion() {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)')

    const listener = (event: MediaQueryListEvent) => {
      setPrefersReducedMotion(event.matches)
    }

    setPrefersReducedMotion(mediaQueryList.matches)

    createEventListener(mediaQueryList, true, 'change', listener)

    return () => {
      createEventListener(mediaQueryList, false, 'change', listener)
    }
  }, [])

  return prefersReducedMotion
}

const createEventListener = (
  mediaQueryList: MediaQueryList,
  add: boolean,
  event: string,
  listener: EventListener
) => {
  const method = add ? 'addEventListener' : 'removeEventListener'
  const oldMethod = add ? 'addListener' : 'removeListener'

  try {
    mediaQueryList[method](event, listener)
  } catch {
    try {
      // Safari
      mediaQueryList[oldMethod](listener)
    } catch (e) {
      console.error(e)
    }
  }
}
