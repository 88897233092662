import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { classes } from '../../../utils/classes'

export type TextInputProps = {
  type?: 'text' | 'email' | 'search'
  name: string
  id: string
  invalid?: boolean
  defaultValue?: string
  placeholder?: string
  inputMode?: 'text' | 'email'
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type = 'text',
      name,
      id,
      invalid,
      defaultValue,
      placeholder,
      inputMode = 'text',
      onChange,
      onBlur,
    },
    ref
  ) => {
    return (
      <input
        className={classes(
          'w-full pl-3 font-sans bg-white border rounded-sm h-11',
          invalid ? 'border-red-500 text-red-500' : 'border-gray-600'
        )}
        {...{
          type,
          ref,
          id,
          name,
          defaultValue,
          onChange,
          onBlur,
          placeholder,
          inputMode,
        }}
      />
    )
  }
)
