import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { classes } from '../../../utils/classes'

export type TextAreaProps = {
  name: string
  id: string
  invalid?: boolean
  placeholder?: string
  defaultValue?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ name, id, invalid, placeholder, defaultValue, onChange, onBlur }, ref) => {
    return (
      <textarea
        className={classes(
          'w-full px-2 py-1 font-sans bg-white border rounded-sm resize-y',
          invalid ? 'border-red-500 text-red-500' : 'border-gray-600'
        )}
        rows={4}
        {...{ ref, id, name, placeholder, defaultValue, onChange, onBlur }}
      />
    )
  }
)
