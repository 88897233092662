import Head from 'next/head'
import { ReactNode, useState } from 'react'
import { classes } from '../../utils/classes'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import { Shade } from '../shade/Shade'
import { SiteNav } from '../sitenav'

type PageProps = {
  title: string
  headerTitle?: string
  metaTags?: { name?: any; content?: any }[]
  children: ReactNode
}

export const Page = ({
  title = '',
  headerTitle = '',
  children,
  metaTags = [],
}: PageProps) => {
  const [shade, setShade] = useState({
    opener: 'SiteNav',
    shade: false,
    menu: false,
    filters: false,
    onClick: () => {},
  })

  const shadeProps = {
    shade,
    setShade,
  }

  return (
    <div className="min-h-screen bg-gray-200 col">
      <Head>
        <title>{title}</title>

        {metaTags.map((tag) => (
          <meta name={tag.name.toString()} content={tag.content.toString()} />
        ))}
      </Head>

      <SiteNav style="main" {...shadeProps} />

      <div className="pt-15">
        {headerTitle ? <Header title={headerTitle} titleSize="large" /> : null}

        <main
          className={classes(
            'max-w-4xl m-auto',
            headerTitle ? 'pt-2' : 'pt-12'
          )}
        >
          {children}
        </main>

        <Shade {...shadeProps} />
        <Footer />
      </div>
    </div>
  )
}
