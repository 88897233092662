import React from 'react'

import { Title, TitleSize } from '../typography'
import { classes } from '../../utils/classes'
import css from './Header.module.scss'

type Size = 'small' | 'medium'

export const Header = ({
  title = 'My Profile',
  size = 'medium',
  titleSize = 'medium',
}: {
  title?: string
  size?: Size
  titleSize?: TitleSize
}) => {
  let containerSize = 'h-64 md:h-80'
  let headerSize = 'h-128'
  let imageSize = 'h-40 translate-y-13'

  if (size === 'small') {
    containerSize = 'h-34 md:h-36'
    headerSize = 'h-96 md:h-110'
    imageSize = 'h-30 md:h-36 translate-y-8 md:translate-y-13'
  }

  return (
    <div className={containerSize}>
      <div
        className={classes(
          'absolute left-0 right-0 overflow-hidden bg-green-700 transform origin-bottom -skew-y-3 translate-y-8 -top-62 md:-top-55',
          headerSize
        )}
      />
      <div
        className={classes(
          'transform -skew-y-1/2 absolute top-0 left-0 right-0 bg-no-repeat bg-top',
          imageSize,
          css.headerImg
        )}
      />
      {!!title && (
        <Title
          className="absolute left-0 right-0 mx-2 text-center text-white mt-34 md:mx-4 md:mt-40"
          size={titleSize}
          data-testid="header-title"
        >
          {title}
        </Title>
      )}
    </div>
  )
}
