import { UseFormRegisterReturn } from 'react-hook-form'

import { CountryRadio } from '../inputs/CountryRadio'
import { IrelandFlag } from '../../../icons/IrelandFlag'
import { UkFlag } from '../../../icons/UkFlag'
import { ErrorMessage, ErrorMessageSpacer } from '../ErrorMessage'

type CountryRadioFieldProps = {
  label?: string
  register: () => UseFormRegisterReturn
  error?: string
}

export const CountryRadioField = ({
  label = 'Country',
  register,
  error,
}: CountryRadioFieldProps) => (
  <>
    <p className="mb-1 font-medium">{label}</p>

    <div className="flex mb-1">
      <CountryRadio
        value="GB"
        countryName="United Kingdom"
        flag={UkFlag}
        {...register()}
      />

      <CountryRadio
        value="IE"
        countryName="Ireland"
        flag={IrelandFlag}
        {...register()}
      />
    </div>

    {error ? <ErrorMessage>{error}</ErrorMessage> : <ErrorMessageSpacer />}
  </>
)
