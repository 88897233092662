import React, { useCallback, useEffect } from 'react'
import { useOktaAuth } from '../components/OktaContext'

declare global {
  interface Window {
    dataLayer: Array<Object> | undefined
  }
}

type Props = {
  id: string
}

// Loads Google Tag Manager
export const GoogleTagManager = React.memo(({ id }: Props) => {
  useEffect(() => {
    // Don't load GTM until we have created the data layer
    sendToGtm({
      'gtm.start': Date.now(),
      event: 'gtm.js',
    })

    const script = document.createElement('script')

    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // Google Tag Manager (noscript for when JS is disabled)
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  )
})

type EventMetaData = {
  event: string
  status: 'success' | 'failure'
  error: undefined | string
}

type EventData = {
  communication_preferences_location?: string
  discount_name?: string
  enrolled_for_discount?: string
}

export const useTrackEvent = () => {
  // useTrackEvent might be used outside the OktaContext provider, e.g. public pages
  // Therefore, do not destructure property 'authState' of oktaAuth as it might be null
  const oktaAuth = useOktaAuth()
  const authState = oktaAuth ? oktaAuth.authState : null

  const track = useCallback(
    ({ event, status, error }: EventMetaData, data: EventData) => {
      sendToGtm({
        account_status: 'Account not verified',
        login_type: undefined,
        loyalty_card: 'No loyalty card linked',
        nandos_card_number: undefined,
        status,
        error,
        user_id: authState?.accessToken?.claims.sub,
        user_state: 'Logged in',
        virtual_page: '/profile',
        event,
        ...data,
      })
    },
    [authState]
  )

  return track
}

function sendToGtm<T extends {}>(event: T) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}
