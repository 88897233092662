import {
  BaseModalProps,
  Modal,
  ModalBody,
  exitAnimationDuration,
  ModalButtons,
} from './components/Modal'
import { P } from './components/typography'
import { FormState, useFormState } from './utils/useFormState'
import { loadedAnimationDuration, Loader } from './components/Loader'
import { timeout } from './utils/timeout'
import { useState } from 'react'
import { ErrorMessage } from './components/form/ErrorMessage'

export interface DeleteContentProps {
  state: FormState
  hasError: boolean
}

const DeleteConfirmationContent = ({ state, hasError }: DeleteContentProps) => {
  if (state === FormState.AwaitingSubmission) {
    return (
      <div className="max-w-xs">
        {hasError ? (
          <div className="mb-6">
            <ErrorMessage big>Unable to delete address</ErrorMessage>
          </div>
        ) : null}
        <P className="mb-8 text-2xl text-center">
          Are you sure you want to delete this address?
        </P>
      </div>
    )
  } else {
    return (
      <div className="mb-8">
        <Loader loaded={state === FormState.Submitted} />
      </div>
    )
  }
}

export interface DeleteAddressProps extends BaseModalProps {
  onDelete: () => Promise<void>
}

export const DeleteAddressConfirmation = ({
  isOpen,
  onClose,
  onDelete,
}: DeleteAddressProps) => {
  const [state, setState] = useFormState()
  const [hasError, setError] = useState(false)

  // Do not close modal whilst loading
  const canClose = state === FormState.AwaitingSubmission

  const handleClose = async () => {
    if (!canClose) return

    onClose()
    await timeout(exitAnimationDuration)
    setError(false)
  }

  const handleDelete = async () => {
    setError(false)
    setState(FormState.Submitting)
    try {
      await onDelete()
      setState(FormState.Submitted)
      await timeout(loadedAnimationDuration)
      onClose()
      await timeout(exitAnimationDuration)
    } catch (e) {
      console.error(e)
      setError(true)
    }
    setState(FormState.AwaitingSubmission)
  }

  const showStickyButtons = state === FormState.AwaitingSubmission

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      blur
      animationKey={String(state)}
    >
      <ModalBody showCloseButton={canClose}>
        <DeleteConfirmationContent state={state} hasError={hasError} />
      </ModalBody>
      {showStickyButtons ? (
        <ModalButtons
          primaryCTAText="delete"
          onPrimaryCTAClick={handleDelete}
          secondaryCTAText="cancel"
          onSecondaryCTAClick={handleClose}
        />
      ) : null}
    </Modal>
  )
}
