import React, { useMemo } from 'react'
import { Button } from './components/buttons/Button'
import { P } from './components/typography'
import { Duration } from 'luxon'

const ResendCode = ({
  countdown,
  onClick,
}: {
  countdown: number
  onClick: () => void
}) => {
  const formattedDuration = useMemo(
    () => Duration.fromMillis(countdown * 1000).toFormat('mm:ss'),
    [countdown]
  )
  return countdown === 0 ? (
    <Button
      type="button"
      secondary
      secondaryClasses="bg-transparent text-blueButton underline text-sm "
      upperCase={false}
      onClick={onClick}
    >
      Didn’t get it? Re-send code
    </Button>
  ) : (
    <P className="text-sm text-center h-11 align-middle inline-block w-full">
      Didn’t get it? Re-send code in {formattedDuration}
    </P>
  )
}

export default ResendCode
