import React, { useEffect } from 'react'

type Props = {
  id: string
}

// Loads Convert Experiments
export const ConvertExperiments = React.memo(({ id }: Props) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = `https://cdn-3.convertexperiments.com/js/${id}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
})
