import React, { MouseEvent } from 'react'
import { List } from './List'
import { NavDataType } from './constants'

/**
 * Describes a link with an optional sub navigation collection of children
 *
 * @function
 * @param       {String} href              Should contain a link to the href target of the initial anchor. This is omitted for items which have a sub menu as tracking was interfering with the event cancellation leading to the link always being followed
 * @param       {string} text              The text of the link.
 * @param       {string} title             A slightly longer descriptive text for the title attribute of the link.
 * @param       {array}  children          An optional collection of menu items
 * @param       {boolean} inList           If we are already in a list, this will be true and force the omission of the opening ul tag.
 * @param       {string} className         An optional class to add to the existing component class attribute.
 * @param       {string} openMenu          useState variable, which will contain the name of the open sub menu if this applies.
 * @param       {function} setOpenMenu     useState function to set the name of the open sub menu on click.
 * @param       {boolean} openMobileMenu   Is true if the menu opener button clicked was the burger menu button for mobile.
 * @param       {function} setOpenMobileMenu useState function to set if the menu is opened using the burger menu button for mobile
 * @constructor
 */

export type LinkProps = {
  href: string
  text: string
  title: string
  target?: string
  children: Array<NavDataType>
  inList: boolean
  className: string
  openMenu: string
  setOpenMenu: (value: string) => void
  openMobileMenu: boolean
  setOpenMobileMenu: () => void
}

export const Link = ({
  href = '',
  target,
  text = '',
  title = '',
  children,
  inList = false,
  className,
  openMenu = '',
  setOpenMenu,
  openMobileMenu,
  setOpenMobileMenu,
}: LinkProps) => {
  function Anchor() {
    const listProps = {
      openMenu,
      setOpenMenu,
      openMobileMenu,
      setOpenMobileMenu,
      text,
    }

    function openMenuFn() {
      setOpenMenu(openMenu !== text ? (text ? text : '') : '')
    }

    function rmClass(e: MouseEvent<HTMLAnchorElement>) {
      const target = e.currentTarget

      target.classList.remove('open')

      const next = target.nextElementSibling
      if (!next) return

      next.classList.remove('open')
    }

    return (
      <>
        {children && children.length ? (
          <>
            <a
              href={href || '#'}
              title={title}
              className={[text === openMenu ? 'open' : null, className]
                .join(' ')
                .trim()}
              aria-haspopup="true"
              role="menuitem"
              target={target}
              aria-expanded={openMenu ? 'true' : 'false'}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (openMenu === text) {
                  setTimeout(openMenuFn, 100)
                  rmClass(e)
                } else {
                  openMenuFn()
                }
              }}
            >
              {text}
            </a>
            <List role="menu" {...listProps}>
              {children}
            </List>
          </>
        ) : (
          <a
            role="menuitem"
            href={href}
            target={target}
            title={title}
            className={className}
          >
            {text}
          </a>
        )}
      </>
    )
  }

  if (inList) {
    return (
      <li
        className={[text === openMenu ? 'open' : null, className]
          .join(' ')
          .trim()}
        role="none"
      >
        <Anchor />
      </li>
    )
  }

  return <Anchor />
}
