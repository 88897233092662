import { Client } from 'urql'
import {
  DeleteJobDocument,
  DeleteJobMutationVariables,
  UpdateUserProfileDocument,
  UpdateUserProfileMutationVariables,
} from '../generated/current-user-graphql'
import {
  OptOutDocument,
  OptOutMutationVariables,
} from '../generated/service-graphql'

export async function unsubscribeUser(
  vars: OptOutMutationVariables,
  client: Client
) {
  return await client.mutation(OptOutDocument, vars).toPromise()
}

export async function updateCurrentUser(
  vars: UpdateUserProfileMutationVariables,
  client: Client
) {
  return await client.mutation(UpdateUserProfileDocument, vars).toPromise()
}

export async function removeCurrentUserJob(
  vars: DeleteJobMutationVariables,
  client: Client
) {
  return await client.mutation(DeleteJobDocument, vars).toPromise()
}
