import { ReactNode } from 'react'
import { classes } from '../../utils/classes'

export type TextComponentProps = {
  // using string instead of listing out the possible options makes typescript
  // complain below that className isn't an allowed prop
  component?: 'h1' | 'h2' | 'h3' | 'p'
  className?: string
  children: string | ReactNode
}

export const P = ({
  component = 'p',
  className = '',
  ...props
}: TextComponentProps) => {
  const Component = component

  return <Component className={classes('font-sans', className)} {...props} />
}

export const H2 = ({ component = 'h2', ...props }: TextComponentProps) => {
  const Component = component

  return <Component className="text-2xl font-medium" {...props} />
}

export type TitleSize = 'card' | 'medium' | 'large'

interface TitleProps extends TextComponentProps {
  size?: TitleSize
}

export const Title = ({
  className = '',
  size = 'medium',
  ...props
}: TitleProps) => {
  const fontSizeClasses = () => {
    switch (size) {
      case 'card':
        return 'text-3xl md:text-4xl'
      case 'medium':
        return 'text-2xl md:text-4xl lg:text-5xl'
      case 'large':
        return 'text-3xl md:text-4xl lg:text-5xl'
    }
  }
  return (
    <h1
      className={classes('font-display', fontSizeClasses(), className)}
      {...props}
    />
  )
}
