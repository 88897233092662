import { useMemo, useState } from 'react'

type useBooleanStateReturn = {
  isEnabled: boolean
  enable: () => void
  disable: () => void
  toggle: () => void
}

/**
 * Convenient React hook for toggling boolean state
 *
 * @param initialValue
 *
 * @example
 * const state = useBooleanState(false)
 *
 * // Read current value
 * state.isEnabled
 *
 * // Toggle current value
 * state.toggle()
 *
 * // Set to a specific value
 * state.enable()
 * state.disable()
 */
export const useBooleanState = (
  initialValue: boolean
): useBooleanStateReturn => {
  const [isEnabled, setValue] = useState(initialValue)

  const { enable, disable, toggle } = useMemo(
    () => ({
      enable: () => setValue(true),
      disable: () => setValue(false),
      toggle: () => setValue((x) => !x),
    }),
    []
  )

  return { isEnabled, enable, disable, toggle }
}
