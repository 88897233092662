import { Country } from './utils/country'

type Nullable<T> = T | null

export type User = {
  uuid: string
  email: string
  created?: string
  lastUpdated?: string
  emailVerified?: string
  loyaltyId?: string
  displayName?: string
  givenName?: string
  familyName?: string
  birthday?: string
  mobileNumber?: string
  marketingOptIn?: Nullable<string>
  jobs?: Array<Job>
  country?: Country
  postcode?: string
}

export type JobType = 'NHS' | 'HSE' | 'PARTNER' | 'STUDENT' | 'NANDOCA'

export type Address = {
  nickName: string
  address1: string
  address2: string
  city: string
  country: string
  deliveryNote: string
  primary: boolean
  zip: string
}

export type Job = {
  id?: string
  type: JobType
  identifier: string
  expiry: string
  disabled?: boolean
}

export const defaultUser: User = {
  uuid: '',
  email: '',
  displayName: '',
  givenName: '',
  birthday: '',
  mobileNumber: '',
}
