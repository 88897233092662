import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'

export const oktaConfigNandoca = (origin: string): OktaAuthOptions => {
  return {
    issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER || '',
    clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID || '',
    redirectUri: origin + '/profile/discount/nandoca/verify',
    scopes: ['openid', 'profile', 'email'],
  }
}

export const oktaConfigCiam = (origin: string): OktaAuthOptions => {
  return {
    issuer: process.env.NEXT_PUBLIC_OIDC_ROOT || '',
    clientId: process.env.NEXT_PUBLIC_PKCE_CLIENT_ID || '',
    redirectUri: origin + '/profile/login',
    postLogoutRedirectUri: origin + '/profile/api/logout',
    scopes: ['openid', 'offline_access'],
    services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
    },
    tokenManager: {
      storage: 'sessionStorage',
    },
  }
}

export const newAuthClient = (
  origin: string,
  config: OktaAuthOptions = oktaConfigNandoca(origin)
): OktaAuth => {
  return new OktaAuth(config)
}
