import React from 'react'
import { Button } from './components/buttons/Button'
import { BaseModalProps, Modal, ModalBodyWithTitle } from './components/Modal'
import { P } from './components/typography'
import MailUs from './icons/MailUs'

const EmailFactorUpdateModal = ({
  isOpen,
  onClose,
  ...props
}: BaseModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <ModalBodyWithTitle title="Changed your email?" showCloseButton>
        <div className="mb-4">
          <MailUs />
        </div>
        <P className="mb-4 text-center">
          Contact our customer support team to update your details.
        </P>
        <Button type="link" href="https://www.nandos.co.uk/help/contact">
          Get in touch
        </Button>
        <Button
          type="button"
          secondary
          secondaryClasses="bg-transparent text-blueButton text-sm"
          onClick={onClose}
          upperCase={false}
        >
          Cancel
        </Button>
      </ModalBodyWithTitle>
    </Modal>
  )
}

export default EmailFactorUpdateModal
