type Props = {
  large?: boolean
}

export const ErrorIcon = ({ large = false }: Props) => {
  const size = large ? 18 : 15

  return (
    <div>
      <svg
        className="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 15 15"
      >
        <circle cx="7.5" cy="7.5" r="7.5" />
        <path
          className="text-white"
          d="M8.144 10.5c.058 0 .105.047.106.105v1.29a.106.106 0 01-.106.105H6.856a.106.106 0 01-.106-.105v-1.29c0-.058.048-.105.106-.105zM8.13 3c.072 0 .12.05.12.124l-.276 5.752c0 .075-.048.124-.12.124h-.708c-.072 0-.12-.05-.12-.124L6.75 3.124c0-.075.048-.124.12-.124z"
        />
      </svg>
    </div>
  )
}
