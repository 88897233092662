import React, { useCallback, useContext } from 'react'

import { Panel, PanelContent, PanelHeader } from './Panel'
import { graphqlUrl } from './utils/graphqlClient'
import { useUser } from './utils/user'
import type { User } from './User'
import { fetchEntity, removeJob } from './api/entity'
import { JobCardBody } from './JobCard'
import { JobCardBodyDisabled } from './JobCardDisabled'
import { Page } from './components/page'
import { Checkbox } from './components/inputs/Checkbox'
import { useMarketingPreferencesCheckbox } from './MarketingPreferences'
import { SignOutContext } from './components/AuthProvider'
import Link from 'next/link'
import { SavedAddresses } from './SavedAddresses'
import { useFeatureFlag } from './utils/useFeatureFlag'
import { MFAManagement } from './MFAManagement'
import { useConfiguration } from './utils/configuration'
import { JobType } from './generated/service-graphql'
import { useDiscountTypeConfig } from './utils/discount'
import { DiscountType } from './api/verification'

export const HomePage = () => {
  const apiUrl = graphqlUrl(process.env.NEXT_PUBLIC_PROFILE_API_V2_URL || '')
  const showSavedAddresses = useFeatureFlag('addresses')
  const mfaManagementEnabled = useConfiguration('mfaManagement')
  const { user, setUser, auth_token, error } = useUser()
  const studentDiscountTypeConfig = useDiscountTypeConfig(DiscountType.Student)

  const onRemoveJob = useCallback(
    async (jobId: string) => {
      if (!auth_token) return

      await removeJob(apiUrl, auth_token, jobId)

      // Refresh user
      const updatedUser = await fetchEntity(apiUrl, auth_token)
      setUser(updatedUser)
    },
    [apiUrl, auth_token, user.uuid]
  )

  const jobs = user?.jobs || []

  const headerTitle = user?.givenName ? `Hey ${user.givenName}` : 'Hey'

  return (
    <Page title="Nando's | My Profile" headerTitle={headerTitle}>
      <div className="flex flex-col items-center">
        {error ? (
          <div className="flex flex-col text-center">
            <p>Something went wrong</p>
            <p>{error.message}</p>
          </div>
        ) : (
          <div className="flex flex-col max-w-lg">
            {jobs.map((job) =>
              job.type === JobType.Student &&
              !studentDiscountTypeConfig ? null : (
                <div key={job.id} className="mt-2">
                  <Panel>
                    <PanelContent withPadding={false}>
                      {job.type === JobType.Student &&
                      studentDiscountTypeConfig &&
                      studentDiscountTypeConfig.disabled ? (
                        <JobCardBodyDisabled key={job.id} job={job} />
                      ) : (
                        <JobCardBody
                          key={job.id}
                          job={job}
                          removeJob={async () => await onRemoveJob(job.id!)}
                        />
                      )}
                    </PanelContent>
                  </Panel>
                </div>
              )
            )}

            <AccountDetails user={user} />

            {showSavedAddresses ? <SavedAddresses /> : null}

            {mfaManagementEnabled ? <MFAManagement user={user} /> : null}

            <MarketingPreferences />

            <LogOut />
          </div>
        )}
      </div>
    </Page>
  )
}

const AccountDetails = ({ user }: { user: User }) => {
  const accountFields = [
    ['Name', user.givenName],
    ['Last name', user.familyName],
    ['Email', user.email],
    user.country ? ['Country', user.country] : null,
    user.birthday ? ['Year of birth', user.birthday.split('-')[0]] : null,
    user.mobileNumber ? ['Phone number', user.mobileNumber] : null,
  ]

  return (
    <div className="mt-6">
      <Panel>
        <PanelHeader
          title="Account details"
          action={
            <p>
              <Link href="/details" legacyBehavior>
                <a className="underline text-blue focus:text-black active:text-black hover:text-black">
                  Manage Account
                </a>
              </Link>
            </p>
          }
        />
        <PanelContent>
          <dl className="grid gap-1 2xs:grid-cols-account-details-card">
            {accountFields.map((field) => {
              if (!field) return null

              const [label, value] = field
              const testId = `account-details-${label
                ?.toLowerCase()
                .split(' ')
                .join('-')}`

              return (
                <React.Fragment key={label}>
                  <dt className="font-medium">{label}</dt>
                  <dd
                    className="mb-4 2xs:mb-0 data-hj-suppress"
                    data-testid={testId}
                  >
                    {value}
                  </dd>
                </React.Fragment>
              )
            })}
          </dl>
        </PanelContent>
      </Panel>
    </div>
  )
}

const MarketingPreferences = () => {
  const marketingPrefs = useMarketingPreferencesCheckbox()

  return (
    <div className="mt-6">
      <Panel>
        <PanelHeader title="Marketing Preferences" />
        <PanelContent>
          <div>
            <Checkbox
              loading={marketingPrefs.loading}
              text="Tick the box if you're down for Extra Hot Nando's news!"
              consent
              detailText="By signing up I confirm I am aged 16 or over"
              checked={marketingPrefs.optedIn || false}
              onChange={marketingPrefs.onClick}
              compact
              testId="marketing-optin"
            />
          </div>
        </PanelContent>
      </Panel>
    </div>
  )
}

const LogOut = React.memo(() => {
  const signOut = useContext(SignOutContext)
  const handleClick = (e: any) => e.detail === 1 && signOut()

  return (
    <div className="flex justify-center mt-12">
      <button
        className="px-8 py-3 font-medium uppercase bg-white border border-black"
        onClick={handleClick}
      >
        Log out
      </button>
    </div>
  )
})
