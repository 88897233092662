import { createClient } from '@urql/core'
import { cacheExchange, Client, fetchExchange } from 'urql'
import { persistedFetchExchange } from '@urql/exchange-persisted-fetch'

export const graphqlUrl = (baseUrl: string) => `${baseUrl}/graphql/current-user`

export const graphqlServiceUrl = (baseUrl: string) =>
  `${baseUrl}/graphql/service`

export function defaultServiceClient(): Client {
  return createClient({
    url: graphqlServiceUrl(process.env.NEXT_PUBLIC_PROFILE_API_V2_URL || ''),
    exchanges: [cacheExchange, persistedFetchExchange(), fetchExchange],
    fetchOptions: () => {
      return {
        headers: {
          authorization: `Bearer ${process.env.PROFILE_API_TOKEN || ''}`,
        },
      }
    },
  })
}

export function defaultCurrentUserClient(token: string, url: string): Client {
  return createClient({
    url: url,
    exchanges: [cacheExchange, persistedFetchExchange(), fetchExchange],
    fetchOptions: () => {
      return {
        headers: { authorization: `Bearer ${token}` },
      }
    },
  })
}
