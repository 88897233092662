import React, { ReactNode } from 'react'
import { Flipped, Flipper } from 'react-flip-toolkit'

import { usePrefersReducedMotion } from '../utils/reducedMotion'

/**
 * A wrapper around `react-flip-toolkit` which disables FLIP animations when
 * the user has `prefers-reduced-motion: reduce` set.
 */

type FlipWrapperProps = {
  flipKey: string
  applyTransformOrigin?: boolean
  children: ReactNode
}

type DecisionData = {
  prefersReducedMotion: boolean
}

export const FlipWrapper = (props: FlipWrapperProps) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  const data: DecisionData = {
    prefersReducedMotion,
  }

  return <Flipper decisionData={data} {...props} />
}

type FlipElementProps = {
  flipId?: string
  inverseFlipId?: string
  translate?: boolean
  scale?: boolean
  opacity?: boolean
  children: ReactNode
}

export const FlipElement = (props: FlipElementProps) => {
  const shouldFlip = (prevData: DecisionData, currentData: DecisionData) =>
    !currentData.prefersReducedMotion

  return <Flipped shouldFlip={shouldFlip} {...props} />
}
