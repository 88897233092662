import { ReactNode } from 'react'
import { Alert } from '../../icons/Alert'
import { classes } from '../../utils/classes'

type Props = {
  big?: boolean
  children: ReactNode
}

export const WarningMessage = ({ big = false, children }: Props) => (
  <div
    className={classes(
      'flex items-start text-black p-2 bg-orange-100 mt-2'
    )}
  >
    <div className={classes('mt-1 text-orange-200')}>
      <Alert />
    </div>
    <p data-testid="error-message" className="mt-1 ml-2 text-black">
      {children}
    </p>
  </div>
)
