export const LeftArrow = ({ color = '#FFF' }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-330 -402) translate(330 402)">
          <circle cx="16" cy="16" r="16" fill="#000" fillOpacity="0" />
          <path
            fill={color}
            d="M24 15L11.83 15 17.42 9.41 16 8 8 16 16 24 17.41 22.59 11.83 17 24 17z"
          />
        </g>
      </g>
    </g>
  </svg>
)
