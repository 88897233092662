import React from 'react'
import { NavDataType } from './constants'
import { List } from './List'

type TitleProps = {
  className: string
  text: string
  inList: boolean
  children: Array<NavDataType>
}

/**
 * Some of the sub menu lists are grouped under a title, this provides that title.
 *
 * @function
 * @param       {string} className         An optional class to add to the existing component class attribute.
 * @param       {string} text              The text of the link.
 * @param       {boolean} inList           If we are already in a list, this will be true and force the omission of the opening ul tag.
 * @param       {array}  children          An optional collection of menu items
 * @constructor
 */
function Title({ className, text, inList, children }: TitleProps) {
  function ListTitle() {
    return (
      <>
        {children && children.length ? (
          <>
            <h4 className={className}>{text}</h4>
            <List text={text} role="menu">
              {children}
            </List>
          </>
        ) : (
          <h4 className={className}>{text}</h4>
        )}
      </>
    )
  }

  if (inList) {
    return (
      <li role="none">
        <ListTitle />
      </li>
    )
  }

  return <ListTitle />
}

export { Title }
