export const navDataIe = {
  top: [
    {
      type: 'LogoLink',
      href: 'https://nandos.ie',
      className: 'logo',
      text: 'Nando’s',
    },
    {
      type: 'List',
      className: 'menu-top',
      hideFromMobile: true,
      children: [
        {
          type: 'Link',
          className: 'lbutton',
          text: 'Log in',
          href: 'https://www.nandos.ie/nandos-id/login',
        },
        {
          type: 'Link',
          className: 'lbutton',
          text: 'Sign up',
          href: 'https://www.nandos.ie/card/auth/register',
        },
        {
          type: 'Link',
          href: 'https://www.nandos.ie/food/menu',
          text: 'Menu',
        },
        {
          type: 'Link',
          text: 'Restaurants',
          children: [
            {
              type: 'RestaurantLocator',
              text: 'To find a Nando’s enter a town or postcode',
              className: 'shorten',
              market: 'IE',
            },
            {
              type: 'Divider',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/restaurants/all',
              text: 'View All Restaurants',
            },
          ],
        },
        {
          type: 'Link',
          text: 'Nando’s Card',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/auth/register',
              text: 'Join now',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/about',
              text: 'About Nando’s Card',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/lost-your-card',
              text: 'Lost Card',
            },
            {
              type: 'Divider',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/nandos-id/login',
              text: 'Log In',
              target: '_blank',
              className: 'login',
            },
          ],
        },
        {
          type: 'Link',
          text: 'Cook',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/products',
              text: 'Products',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/recipes',
              text: 'Recipes',
            },
          ],
        },
        {
          type: 'Link',
          href: 'https://www.nandos.ie/food/gift-card',
          text: 'Gift Cards',
        },
        {
          type: 'Link',
          className: 'explore',
          text: 'About Us',
          children: [
            {
              type: 'Title',
              text: "Safety At Nando's",
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/keeping-safe',
              text: 'Keeping you safe',
            },
            {
              type: 'Title',
              text: 'About',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/about-us/food',
              text: 'Food',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/carbonneutral',
              text: 'Carbon Neutral',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/fightingmalaria',
              text: 'Fighting Malaria',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/being-sustainable',
              text: 'Being Sustainable',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/sustainability/carbon-jargon',
              text: 'Carbon Jargon',
            },
            {
              type: 'Title',
              text: 'Connect',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/app',
              text: 'The Nando’s App',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/blog',
              text: 'News',
            },
            {
              type: 'Title',
              text: 'Experience',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/this-is-peri-peri',
              text: 'This is PERI-PERi',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/music',
              text: 'Music',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/art',
              text: 'Art',
            },
          ],
        },
        {
          type: 'Link',
          text: 'Help',
          children: [
            {
              type: 'Title',
              text: 'FAQs',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/covid-19',
              text: 'Search FAQs',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/covid-19',
              text: 'COVID-19',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/my-account',
              text: 'My Account',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-food',
              text: 'Food',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/online-ordering',
              text: 'Online Ordering',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-restaurants',
              text: 'Restaurants',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/nandos-cards-gift-cards-discounts',
              text: 'Nando’s Card & Discounts',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-restaurants#i-am-organising-fundraising-event-can-you-help',
              text: 'Fundraising Requests',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/sustainability',
              text: 'Sustainability',
            },
            {
              type: 'Title',
              text: 'Contact',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/contact',
              text: 'Contact Us',
            },
            {
              type: 'Title',
              text: 'Resources',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/press-gallery',
              text: 'Press Gallery',
            },
          ],
        },
        {
          type: 'Divider',
        },
        {
          type: 'Link',
          href: 'https://careers.nandos.ie/',
          text: 'Careers',
          className: 'careers',
        },
      ],
    },
    {
      type: 'List',
      className: 'endgroup',
      children: [
        {
          type: 'Link',
          className: 'login',
          target: '_blank',
          title: 'Nando’s Profile',
          href: 'https://www.nandos.co.uk/profile',
          text: 'Profile',
        },
        {
          className: 'order',
          type: 'Link',
          href: 'https://www.nandos.ie/order/search',
          title: 'Ordering online',
          text: 'Order online',
        },
        {
          type: 'MobileNavigation',
          className: 'mobileOpener',
        },
      ],
    },
  ],
}
