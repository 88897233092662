import React from 'react'
import { useCountry } from '../../utils/country'
import { SiteNav } from '../sitenav'
import { useFeatureFlag } from '../../utils/useFeatureFlag'
React.useLayoutEffect = React.useEffect
/**
 * Display all information for the footer. Uses the SiteNav component.
 *
 * @function
 * @constructor
 */
export const Footer = () => {
  // Feature flag for hiding social icons within the app
  // https://nandosuk.atlassian.net/browse/IDT-1346
  const hideSocials = useFeatureFlag('hide_social_icons')

  const country = useCountry()

  const privacyPolicyLink =
    country === 'GB'
      ? 'https://www.nandos.co.uk/privacy-policy'
      : 'https://www.nandos.ie/privacy-policy'

  const tcLink =
    country === 'GB'
      ? 'https://www.nandos.co.uk/nandos-terms-and-conditions'
      : 'https://www.nandos.ie/nandos-terms-and-conditions'

  return (
    <footer className="footer">
      <SiteNav style="footer" />
      <div className="bottom">
        {hideSocials ? null : (
          <>
            <h4 data-testid="social_header">Stay in the loop</h4>
            <ul data-testid="social_icons">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="fb"
                  href="https://www.facebook.com/Nandos.UnitedKingdom"
                >
                  facebook
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="tw"
                  href="https://twitter.com/NandosUK"
                >
                  twitter
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="ig"
                  href="http://instagram.com/nandosuk"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="sc"
                  href="https://www.snapchat.com/add/nandosuk"
                >
                  SnapChat
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="yt"
                  href="https://www.youtube.com/channel/UCVgYYZS_7RE-Hh9dFVMo8Rw"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="sp"
                  href="https://play.spotify.com/user/nandosuk"
                >
                  spotify
                </a>
              </li>
            </ul>
          </>
        )}
        © Nando’s {new Date().getFullYear()} |{' '}
        <a href={tcLink}>Terms and conditions</a> |{' '}
        <a href={privacyPolicyLink}>Privacy Policy</a>
      </div>
    </footer>
  )
}
