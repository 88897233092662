import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BaseModalProps,
  Modal,
  ModalBodyAsLoader,
  ModalBodyWithTitle,
} from './components/Modal'
import { SMSFactor } from './utils/mfa'
import { TextField } from './components/form/fields/TextField'
import { Button } from './components/buttons/Button'
import { useForm, Controller } from 'react-hook-form'
import { FormState, useFormState } from './utils/useFormState'
import { loadedAnimationDuration } from './components/Loader'
import { timeout } from './utils/timeout'
import { P } from './components/typography'
import { PhoneNumber } from './components/inputs/PhoneNumber'
import { isValidPhoneNumber } from './utils/phone_number'
import { Country } from './utils/country'
import ResendCode from './ResendCode'
import { ErrorMessage } from './components/form/ErrorMessage'

interface SMSFactorEnrolModalProps extends BaseModalProps {
  factor: SMSFactor
  updateMode?: boolean
  onEnrol: (phoneNumber: string) => Promise<SMSFactor | undefined>
  onActivate: (factorId: string, passCode: string) => Promise<void>
}

type PhoneNumberFormInputs = {
  phoneNumber: string
}
type PassCodeFormInputs = {
  passCode: string
}

enum Step {
  ENROL,
  ACTIVATE,
}

const SMSFactorEnrolModal = React.memo(
  ({
    onClose,
    factor,
    onEnrol,
    onActivate,
    isOpen,
    updateMode = false,
    ...props
  }: SMSFactorEnrolModalProps) => {
    const { phoneNumber, country } = factor
    const [step, setStep] = useState(Step.ENROL)
    const [formState, setFormState] = useFormState()
    const [submissionError, setError] = useState('')
    const closeAndReset = useCallback(async () => {
      onClose()
      await timeout(1 * 1000)
      setFormState(FormState.AwaitingSubmission)
      setStep(Step.ENROL)
      setError('')
    }, [onClose])
    const enrol = useCallback(
      async (phoneNumber: string) => {
        setFormState(FormState.Submitting)
        setError('')
        try {
          const factor = await onEnrol(phoneNumber)
          if (factor && factor.status === 'PENDING_ACTIVATION') {
            setFormState(FormState.AwaitingSubmission)
            setStep(Step.ACTIVATE)
          } else if (factor && factor.status === 'ACTIVE') {
            setFormState(FormState.Submitted)
            await timeout(loadedAnimationDuration)
            closeAndReset()
          }
        } catch (e) {
          console.error('Submission error:', e)

          setError('Please double check your phone number and try again.')
          setFormState(FormState.AwaitingSubmission)
          return
        }
      },
      [onEnrol]
    )
    const goToEnrol = useCallback(() => setStep(Step.ENROL), [])
    const resendCode = useCallback(async () => {
      if (phoneNumber) {
        return enrol(phoneNumber)
      }
    }, [phoneNumber, onEnrol])
    const activate = useCallback(
      async (passCode: string) => {
        setFormState(FormState.Submitting)
        setError('')
        try {
          await onActivate(factor.id, passCode)
          setFormState(FormState.Submitted)
          await timeout(loadedAnimationDuration)
          closeAndReset()
        } catch (e) {
          console.error('Submission error:', e)

          setError('Please enter the correct 6 digit code and try again.')
          setFormState(FormState.AwaitingSubmission)
          return
        }
      },
      [onActivate, onClose, factor]
    )
    return (
      <Modal isOpen={isOpen} onClose={closeAndReset} {...props}>
        {formState === FormState.AwaitingSubmission ? (
          step === Step.ENROL ? (
            <ModalBodyEnrol
              phoneNumber={phoneNumber || ''}
              country={country}
              onSubmit={enrol}
              onCancel={closeAndReset}
              submissionError={submissionError}
              updateMode={updateMode}
            />
          ) : (
            <ModalBodyActivate
              onBack={goToEnrol}
              onResend={resendCode}
              onSubmit={activate}
              onCancel={closeAndReset}
              submissionError={submissionError}
              updateMode={updateMode}
            />
          )
        ) : (
          <ModalBodyAsLoader loaded={formState === FormState.Submitted} />
        )}
      </Modal>
    )
  }
)

export default SMSFactorEnrolModal

const ModalBodyEnrol = ({
  phoneNumber,
  country,
  onSubmit,
  onCancel,
  submissionError,
  updateMode,
}: {
  phoneNumber: string
  country: Country | undefined
  onSubmit: (phoneNumber: string) => Promise<void>
  onCancel: () => void
  submissionError: string
  updateMode: boolean
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneNumberFormInputs>({
    defaultValues: {
      phoneNumber,
    },
  })

  const submitPhoneNumberRef = useRef<HTMLInputElement>(null)
  const submitPhoneNumberForm = useCallback(() => {
    submitPhoneNumberRef?.current?.click()
  }, [submitPhoneNumberRef])
  const onPhoneNumberSubmit = async (data: FormData) => {
    const phoneNumber = data.get('phoneNumber') as string
    await onSubmit(phoneNumber)
  }
  return (
    <ModalBodyWithTitle
      title={updateMode ? 'Changed your number?' : 'Enable text message'}
      showCloseButton
    >
      {submissionError ? (
        <div className="mb-4">
          <ErrorMessage big>{submissionError}</ErrorMessage>
        </div>
      ) : null}
      <P className="mb-4">
        {updateMode
          ? 'Update your details to keep your account safe.'
          : 'This is just to keep your account safe – we won’t message 24/7, promise.'}
      </P>
      <form
        className="flex flex-col w-full"
        onSubmit={handleSubmit((data) => {
          const formData = new FormData()

          Object.entries(data).forEach(([key, value]) => {
            formData.set(key, value)
          })

          onPhoneNumberSubmit(formData)
        })}
      >
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={phoneNumber || ''}
          rules={{
            validate: (phone) => {
              // When updating phone number, it cannot be the same as the one already enrolled
              return (
                isValidPhoneNumber(phone) &&
                (!updateMode || phone.replace(/[() ]/g, '') !== phoneNumber)
              )
            },
          }}
          render={({ field: { onChange } }) => (
            <PhoneNumber
              name="phoneNumber"
              label={updateMode ? 'Enter new number' : 'Enter number'}
              errorMessage={
                updateMode
                  ? 'Please enter a valid phone number and it must be different from your current one.'
                  : 'Please enter a valid phone number.'
              }
              defaultValue={phoneNumber}
              defaultCountry={country || 'GB'}
              invalid={!!errors.phoneNumber}
              onChange={onChange}
            />
          )}
        />
        <input type="submit" ref={submitPhoneNumberRef} hidden />
      </form>
      <Button type="button" onClick={submitPhoneNumberForm}>
        {updateMode ? 'Update Text' : 'Enable Text'}
      </Button>
      <Button
        type="button"
        secondary
        secondaryClasses="bg-transparent text-blueButton text-sm"
        onClick={onCancel}
        upperCase={false}
      >
        Cancel
      </Button>
    </ModalBodyWithTitle>
  )
}

const ModalBodyActivate = ({
  onBack,
  onResend,
  onSubmit,
  onCancel,
  submissionError,
  updateMode = false,
}: {
  onBack: () => void
  onResend: () => Promise<void>
  onSubmit: (passCode: string) => Promise<void>
  onCancel: () => void
  submissionError: string
  updateMode: boolean
}) => {
  const [resendCountdown, setResendCountdown] = useState<number>(30)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PassCodeFormInputs>({
    defaultValues: {
      passCode: '',
    },
  })
  const submitPassCodeRef = useRef<HTMLInputElement>(null)

  const submitPassCodeForm = useCallback(() => {
    submitPassCodeRef?.current?.click()
  }, [submitPassCodeRef])
  const onPassCodeSubmit = async (data: FormData) => {
    const passCode = data.get('passCode') as string
    await onSubmit(passCode)
  }
  const resend = async () => {
    await onResend()
    setResendCountdown(30)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendCountdown === null) return
      if (resendCountdown > 0) {
        setResendCountdown(resendCountdown - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [resendCountdown])

  return (
    <ModalBodyWithTitle
      title={updateMode ? 'Confirm new number' : 'Enable text message'}
      showCloseButton
      showBackButton
      onBack={onBack}
    >
      {submissionError ? (
        <div className="mb-4">
          <ErrorMessage big>{submissionError}</ErrorMessage>
        </div>
      ) : null}
      <P className="mb-4">We’ve just sent you a six-digit verification code.</P>
      <form
        className="flex flex-col w-full"
        onSubmit={handleSubmit((data) => {
          const formData = new FormData()

          Object.entries(data).forEach(([key, value]) => {
            formData.set(key, value)
          })

          onPassCodeSubmit(formData)
        })}
      >
        <TextField
          label="Enter it here"
          error={errors.passCode ? 'Please enter a valid one time code' : ''}
          {...register('passCode', {
            required: true,
            pattern: /^[0-9]{6}$/,
          })}
        />
        <input type="submit" ref={submitPassCodeRef} hidden />
      </form>{' '}
      {resendCountdown !== null ? (
        <ResendCode countdown={resendCountdown} onClick={resend} />
      ) : null}
      <Button type="button" onClick={submitPassCodeForm}>
        Finish
      </Button>
      <Button
        type="button"
        secondary
        secondaryClasses="bg-transparent text-blueButton text-sm"
        onClick={onCancel}
        upperCase={false}
      >
        Cancel
      </Button>
    </ModalBodyWithTitle>
  )
}
