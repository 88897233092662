export const footerDataUk = {
  top: [
    {
      type: 'List',
      children: [
        {
          type: 'Link',
          className: 'fLink',
          text: 'Food',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/food/menu',
              text: 'Menu',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/food/recipes',
              text: 'Recipes',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/food/gift-card',
              text: 'Gift Cards',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/food/our-food',
              text: 'Our Food',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Restaurants',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/restaurants',
              text: 'Find a Nando’s',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/restaurants/all',
              text: 'View All Restaurants',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Nando’s Card',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/card/auth/register',
              text: 'Join now',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/card/about',
              text: 'About Nando’s Card',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/nandos-id/forgot-password',
              text: 'Forgot Your Password?',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/card/lost-your-card',
              text: 'Lost Card',
            },
            {
              type: 'Divider',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/nandos-id/login',
              text: 'Log In',
              className: 'login',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Explore',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/this-is-peri-peri',
              text: 'This is PERi-PERi',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/art',
              text: 'Art',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/music',
              text: 'Music',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/app',
              text: 'The Nando’s App',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/blog',
              text: 'Our Blog',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/fightingmalaria',
              text: 'Fighting Malaria',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/explore/being-sustainable',
              text: 'Being Sustainable',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Help',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/our-food',
              text: 'Food',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/online-ordering',
              text: 'Online Ordering',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/our-restaurants',
              text: 'Restaurants',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/nandos-cards-gift-cards-discounts',
              text: 'Nando’s Card & Discounts',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/our-restaurants#corporate',
              text: 'Fundraising Requests',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/help/contact',
              text: 'Contact Us',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/press-gallery',
              text: 'Press Gallery',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Legal',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/nandos-terms-and-conditions',
              text: 'Terms & Conditions',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/privacy-policy',
              title: 'Nando’s privacy policy',
              text: 'Privacy Policy',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/cookies-policy',
              title: 'Nando’s cookie policy',
              text: 'Cookies Policy',
            },
            {
              type: 'Link',
              href: 'javascript:truste.eu.reopenBanner();',
              title: 'Cookie Preferences',
              text: 'Cookie Preferences',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/modern-slavery-statement',
              text: 'Slavery Statement',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/genderpayreport',
              text: 'Gender Pay Gap Report',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/insurance-policy-details',
              text: 'Insurance Policy Details',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.co.uk/nghl-tax-strategy-2020',
              text: 'NGHL Tax Strategy',
            },
          ],
        },
      ],
    },
  ],
}
