import React from 'react'
import { RenderMenu } from './RenderMenu'
import { NavDataType } from './constants'

/**
 * Top level menu component
 *
 * @function
 * @param       {array}  top               An array of menu items
 * @param       {string} openMenu          useState variable, which will contain the name of the open sub menu if this applies.
 * @param       {function} setOpenMenu     useState function to set the name of the open sub menu on click.
 * @param       {boolean} openMobileMenu   Is true if the menu opener button clicked was the burger menu button for mobile.
 * @param       {function} setOpenMobileMenu useState function to set if the menu is opened using the burger menu button for mobile
 * @constructor
 */

export type MenuProps = {
  top: Array<NavDataType>
  openMenu: string
  setOpenMenu: (value: string) => void
  openMobileMenu: boolean
  setOpenMobileMenu: (value: boolean) => void
}
export const Menu = ({
  top,
  openMenu,
  setOpenMenu,
  openMobileMenu,
  setOpenMobileMenu,
}: MenuProps) => {
  if (!top.length) {
    return null
  }

  const renderMenuProps = {
    aria: "Nando's website navigation menu",
    openMenu,
    setOpenMenu,
    openMobileMenu,
    setOpenMobileMenu,
    role: 'menubar',
  }

  return (
    <nav role="navigation">
      <RenderMenu {...renderMenuProps}>{top}</RenderMenu>
    </nav>
  )
}
