import React, { MouseEvent } from 'react'

import { classes } from '../../utils/classes'
import css from './Checkbox.module.scss'

export type CheckboxProps = {
  loading?: boolean
  checked: boolean
  text: string
  consent?: boolean
  detailText?: string
  onChange: () => void
  compact?: boolean
  testId?: string
}

export const Checkbox = ({
  loading = false,
  checked = false,
  text = '',
  consent = false,
  detailText = '',
  onChange,
  compact = false,
  testId = '',
}: CheckboxProps) => {
  const onClick = (e: MouseEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    onChange()
  }

  return (
    <label
      className={classes(
        'relative flex text-base cursor-pointer',
        compact ? '' : 'items-center'
      )}
      onClick={(e) => onClick(e)}
    >
      <input
        className={classes(
          'absolute w-6 h-6 rounded opacity-0 cursor-pointer',
          compact ? 'mt-1' : ''
        )}
        type="checkbox"
        checked={checked}
        onChange={() => {}}
        data-testid={testId}
      />
      <span
        className={classes(
          css.checkbox,
          'w-6 h-6 mr-6 rounded border border-black transition-all duration-300 ease-out cursor-pointer',
          compact ? 'mt-1' : '',
          checked
            ? 'bg-black opacity-100 transform scale-100'
            : 'bg-transparent',
          checked ? css.checkboxChecked : '',
          loading ? 'animate-pulse' : ''
        )}
      ></span>
      <span className="w-10/12 max-w-sm text-left">
        {text}
        {consent && (
          <p
            className={classes(
              compact ? 'pt-1' : 'pt-2',
              'text-sm text-gray-800',
              loading ? 'animate-pulse' : ''
            )}
          >
            {detailText}
          </p>
        )}
      </span>
    </label>
  )
}
