import React, { useCallback, useMemo, useState } from 'react'

import { Panel, PanelContent, PanelHeader } from './Panel'
import { P } from './components/typography'
import { ButtonLink } from './components/typography/Link'
import {
  AddNewAddressModal,
  EditAddressModal,
  EditAddressProps,
} from './AddressModal'
import { useBooleanState } from './utils/useBooleanState'
import { TooManyAddressesAlert } from './TooManyAddressesAlert'
import { DeleteAddressConfirmation } from './DeleteAddressConfirmation'
import {
  Country,
  useAddNewAddressMutation,
  useUsersAddressesQuery,
  useDeleteAddressMutation,
  useEditAddressMutation,
} from './generated/current-user-graphql'
import { Address, AddressFields } from './savedAddresses/address'
import { Loader } from './components/Loader'
import { classes } from './utils/classes'
import { absoluteCenter } from './utils/css'

export const SavedAddresses = React.memo(() => {
  const context = useMemo(
    () => ({
      // If the query returns an empty list, urql's cache doesn't know what types
      // are returned by the query so we tell it here to make sure:
      // https://formidable.com/open-source/urql/docs/basics/document-caching/
      additionalTypenames: ['Address'],
    }),
    []
  )

  const [addressesQuery] = useUsersAddressesQuery({ context })

  const [, addNewAddress] = useAddNewAddressMutation()
  const [, deleteAddress] = useDeleteAddressMutation()
  const [, editAddress] = useEditAddressMutation()

  const { data, fetching, error } = addressesQuery

  const addresses = data?.addresses || []

  const newAddrModal = useBooleanState(false)
  const tooManyAddressesMsg = useBooleanState(false)
  const deleteAddressConfirmation = useBooleanState(false)

  const [deleteAddressId, setDeleteAddressId] = useState<string | null>(null)

  const onAddNewAddress = async (address: AddressFields) => {
    const { error } = await addNewAddress({
      ...address,
      primary: address.primary || false,
    })

    if (error) throw error
  }

  const onEditAddress = (id: string) => async (address: AddressFields) => {
    const {
      primary,
      line1,
      line2,
      city,
      postcode,
      country,
      deliveryInstructions,
    } = address

    const { error } = await editAddress({
      id,
      line1,
      line2,
      city,
      postcode,
      country,
      deliveryInstructions,
      newPrimaryAddressId: newPrimaryAddressId(id, primary),
    })

    if (error) throw error
  }

  const newPrimaryAddressId = (id: string, primary: boolean) => {
    if (primary) return id

    const curPrimaryId = addresses.find((addr) => addr.primary)?.id || id

    if (curPrimaryId === id) {
      // Set next most recently updated address as primary

      const nextPrimaryId =
        addresses.filter((addr) => addr.id !== id)[0]?.id || id

      return nextPrimaryId
    }

    return curPrimaryId
  }

  const onDeleteAddress = (id: string) => async () => {
    const { error } = await deleteAddress(
      { id },
      {
        additionalTypenames: ['Address'],
      }
    )

    if (error) throw error
  }

  const { setAddressToEdit, props: editModalProps } = useEditAddressModal({
    canMakePrimaryAddress: addresses && addresses.length > 1,
    onEditAddress,
    onDeleteAddress,
  })

  const setAddressToDelete = (id: string) => {
    setDeleteAddressId(id)
    deleteAddressConfirmation.enable()
  }

  const onClickAddNew = () => {
    if (addresses.length < 10) {
      newAddrModal.enable()
    } else {
      tooManyAddressesMsg.enable()
    }
  }

  return (
    <div className="mt-6">
      <Panel>
        <PanelHeader
          title="Saved Addresses"
          action={
            <button
              className="underline text-blue focus:text-black active:text-black hover:text-black"
              onClick={onClickAddNew}
            >
              Add new
            </button>
          }
        />
        {error ? (
          <P className="mx-5 text-center my-14 md:mx-28">
            There was an error loading your saved addresses. Please try again.
          </P>
        ) : addresses.length === 0 ? (
          <div className="relative">
            <P
              className={classes(
                'mx-5 text-center text-gray-800 my-14 md:mx-28 transition-opacity',
                fetching ? 'opacity-0' : ''
              )}
            >
              Add your PERi important places here to make checkout faster next
              time.
            </P>
            {fetching ? (
              <div className={absoluteCenter}>
                <Loader loaded={!fetching} />
              </div>
            ) : null}
          </div>
        ) : (
          addresses.map((addr, i) => (
            <PanelContent
              key={addr.id}
              withBottomBorder={i !== addresses.length - 1}
            >
              <SavedAddress
                address={addr}
                onEdit={() => setAddressToEdit(addr)}
                onDelete={() => setAddressToDelete(addr.id)}
              />
            </PanelContent>
          ))
        )}
      </Panel>

      <AddNewAddressModal
        isOpen={newAddrModal.isEnabled}
        onClose={newAddrModal.disable}
        onSave={onAddNewAddress}
        canMakePrimaryAddress={addresses.length > 0}
      />
      <EditAddressModal {...editModalProps} />
      <TooManyAddressesAlert
        isOpen={tooManyAddressesMsg.isEnabled}
        onClose={tooManyAddressesMsg.disable}
      />
      {deleteAddressId != null ? (
        <DeleteAddressConfirmation
          isOpen={deleteAddressConfirmation.isEnabled}
          onClose={deleteAddressConfirmation.disable}
          onDelete={onDeleteAddress(deleteAddressId)}
        />
      ) : null}
    </div>
  )
})

const SavedAddress = ({
  address,
  onEdit,
  onDelete,
}: {
  address: AddressFields
  onEdit: () => void
  onDelete: () => void
}) => {
  const { line1, line2, city, postcode, deliveryInstructions, primary } =
    address

  return (
    <div className="break-words">
      <div className="flex justify-between">
        <div className="w-3/4 data-hj-suppress">
          <P>{line1}</P>
          {line2 ? <P>{line2}</P> : null}
          {city || postcode ? (
            <P>
              {city ? city + ' ' : null}
              {postcode ? postcode : null}
            </P>
          ) : null}
        </div>
        <div>{primary ? <PrimaryFlag /> : null}</div>
      </div>
      <P className="mt-2 text-gray-800">
        <strong>Delivery Instructions:</strong>{' '}
        {deliveryInstructions ? deliveryInstructions : '...'}
      </P>
      <ButtonLink
        className="pt-3 pr-4 mr-4 text-base underline"
        onClick={onEdit}
      >
        Edit
      </ButtonLink>
      <ButtonLink className="px-4 pt-3 text-base underline" onClick={onDelete}>
        Delete
      </ButtonLink>
    </div>
  )
}

const PrimaryFlag = () => (
  <div className="px-2 text-white transform bg-red-700 rounded-full -rotate-3">
    <P>Primary</P>
  </div>
)

type useEditAddressModalArgs = {
  canMakePrimaryAddress: boolean
  onEditAddress: (id: string) => (addr: AddressFields) => Promise<void>
  onDeleteAddress: (id: string) => () => Promise<void>
}

type useEditAddressModalReturn = {
  setAddressToEdit: (address: Address) => void
  props: EditAddressProps
}

// After the user has opened the EditAddressModal and clicked delete we still
// want the modal to hang around for a bit for the exit animations. So instead
// of rendering a modal for each address in the array of addresses we separate
// it out into it's own state
const useEditAddressModal = ({
  canMakePrimaryAddress,
  onEditAddress,
}: useEditAddressModalArgs): useEditAddressModalReturn => {
  type State = Address | null

  const [address, setAddressToEdit] = useState<State>(null)

  const clearEditModal = useCallback(() => {
    setAddressToEdit(null)
  }, [setAddressToEdit])

  return {
    setAddressToEdit,
    props: {
      canMakePrimaryAddress,
      isOpen: !!address,
      onClose: clearEditModal,
      initialValue: address || blankAddress,
      onSave: onEditAddress(address?.id || ''),
    },
  }
}

const blankAddress: AddressFields = {
  primary: false,
  line1: '',
  line2: '',
  city: '',
  postcode: '',
  country: Country.UnitedKingdom,
  deliveryInstructions: '',
}
