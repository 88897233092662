import React, { ReactNode } from 'react'

export type LabelProps = {
  forId: string
  required?: boolean
  children: ReactNode
}

export const Label = ({ forId, required, children }: LabelProps) => (
  <label htmlFor={forId} className="block mb-1 font-medium">
    {children}
    {required ? '*' : null}
  </label>
)
