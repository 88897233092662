import { removeCurrentUserJob, updateCurrentUser } from '../entity/mutations'
import { getCurrentUser } from '../entity/queries'
import {
  Country as APICountry,
  OptInStatus,
} from '../generated/current-user-graphql'
import { User } from '../User'
import { Country } from '../utils/country'
import { defaultCurrentUserClient } from '../utils/graphqlClient'

export async function fetchEntity(
  profileApiUrl: string,
  token: string
): Promise<User> {
  const client = defaultCurrentUserClient(token, profileApiUrl)

  const res = await getCurrentUser(client)

  if (res.error) {
    throw new Error(`Unable to get user: ${res.error.message}`)
  }

  return {
    ...res.data,
    marketingOptIn:
      res.data.marketingOptInStatus == OptInStatus.OptIn
        ? res.data.marketingOptInUpdated
        : undefined,
    country: countryFromAPI(res.data.country),
  }
}

export async function updateEntity(
  profileApiUrl: string,
  token: string,
  user: Partial<User>
): Promise<User> {
  const client = defaultCurrentUserClient(token, profileApiUrl)

  const res = await updateCurrentUser(
    {
      details: {
        ...user,
        country: countryForAPI(user.country),
      },
    },
    client
  )

  if (res.error) {
    throw new Error(`Unable to update user: ${res.error.message}`)
  }

  return await fetchEntity(profileApiUrl, token)
}

export async function removeJob(
  profileApiUrl: string,
  token: string,
  id: string
): Promise<void> {
  const client = defaultCurrentUserClient(token, profileApiUrl)

  const res = await removeCurrentUserJob({ id }, client)

  if (res.error) {
    throw new Error(`Unable to remove job: ${res.error.message}`)
  }
}

function countryFromAPI(country: APICountry): Country | undefined {
  switch (country) {
    case APICountry.UnitedKingdom:
      return 'GB'
    case APICountry.RepublicOfIreland:
      return 'IE'
  }
}

function countryForAPI(country: Country | undefined): APICountry | undefined {
  switch (country) {
    case 'GB':
      return APICountry.UnitedKingdom
    case 'IE':
      return APICountry.RepublicOfIreland
    default:
      return undefined
  }
}
