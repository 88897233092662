import { useEffect, useState } from 'react'

/**
 * Returns `true` when rendering on the client, and `false` when server-side
 * rendering.
 */
export const useIsClientSide = () => {
  const [client_side, set_is_client_side] = useState(false)

  useEffect(() => {
    set_is_client_side(true)
  }, [])

  return client_side
}

/**
 * Lazily create a value client side and return it. Returns `null` server side.
 */
export function useOnClientSideOnly<T>(make: () => T): T | null {
  const [state, setState] = useState<T | null>(null)

  useEffect(() => {
    setState(make)
  }, [])

  return state
}
