export const footerDataIe = {
  top: [
    {
      type: 'List',
      children: [
        {
          type: 'Link',
          className: 'fLink',
          text: 'Food',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/menu',
              text: 'Menu',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/recipes',
              text: 'Recipes',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/gift-card',
              text: 'Gift Cards',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/food/our-food',
              text: 'Our Food',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Restaurants',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/restaurants',
              text: 'Find a Nando’s',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/restaurants/all',
              text: 'View All Restaurants',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Nando’s Card',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/auth/register',
              text: 'Join now',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/about',
              text: 'About Nando’s Card',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/nandos-id/forgot-password',
              text: 'Forgot Your Password?',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/card/lost-your-card',
              text: 'Lost Card',
            },
            {
              type: 'Divider',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/nandos-id/login',
              text: 'Log In',
              className: 'login',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Explore',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/this-is-peri-peri',
              text: 'This is PERi-PERi',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/art',
              text: 'Art',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/music',
              text: 'Music',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/app',
              text: 'The Nando’s App',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/blog',
              text: 'Our Blog',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/fightingmalaria',
              text: 'Fighting Malaria',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/explore/being-sustainable',
              text: 'Being Sustainable',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Help',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-food',
              text: 'Food',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/online-ordering',
              text: 'Online Ordering',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-restaurants',
              text: 'Restaurants',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/nandos-cards-gift-cards-discounts',
              text: 'Nando’s Card & Discounts',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/our-restaurants#corporate',
              text: 'Fundraising Requests',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/help/contact',
              text: 'Contact Us',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/press-gallery',
              text: 'Press Gallery',
            },
          ],
        },
        {
          type: 'Link',
          className: 'fLink',
          text: 'Legal',
          children: [
            {
              type: 'Link',
              href: 'https://www.nandos.ie/nandos-terms-and-conditions',
              text: 'Terms & Conditions',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/privacy-policy',
              title: 'Nando’s privacy policy',
              text: 'Privacy Policy',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/cookies-policy',
              title: 'Nando’s cookie policy',
              text: 'Cookies Policy',
            },
            {
              type: 'Link',
              href: 'javascript:truste.eu.reopenBanner();',
              title: 'Cookie Preferences',
              text: 'Cookie Preferences',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/modern-slavery-statement',
              text: 'Slavery Statement',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/genderpayreport',
              text: 'Gender Pay Gap Report',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/insurance-policy-details',
              text: 'Insurance Policy Details',
            },
            {
              type: 'Link',
              href: 'https://www.nandos.ie/nghl-tax-strategy-2020',
              text: 'NGHL Tax Strategy',
            },
          ],
        },
      ],
    },
  ],
}
