import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { Checkbox } from '../inputs/Checkbox'
import { Label } from '../Label'

export type CheckboxFieldProps = {
  label: string
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const CheckboxField = React.forwardRef<
  HTMLInputElement,
  CheckboxFieldProps
>(({ label, name, onChange, onBlur }, ref) => {
  const id = `field-${name}`

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <Checkbox
          {...{
            ref,
            id,
            name,
            onChange,
            onBlur,
          }}
        />
      </div>
      <Label forId={id} bottomMargin={false}>
        {label}
      </Label>
    </div>
  )
})
