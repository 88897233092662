import React from 'react'

export const BinCircled = ({ d = 24 }: { d?: number }) => {
  return (
    <svg
      width={d}
      height={d}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="12"
        fill="black"
        fill-opacity="0.01"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 7.72222H18V9.16667H8V7.72222H10.5L11.2143 7H14.7857L15.5 7.72222ZM10.1429 20C9.35714 20 8.71429 19.35 8.71429 18.5556V10.5012H17.2857V18.5556C17.2857 19.35 16.6429 20 15.8571 20H10.1429Z"
        fill="black"
      />
    </svg>
  )
}
