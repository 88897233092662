import React, { useEffect, useState, useRef } from 'react'

//https://www.nandos.co.uk/eat/restaurants/search?lat=51.509460353927&lon=-0.013663489845877&search=e140hf#results
const lat = 51.509460353927
const lon = -0.013663489845877
let inst = 1

type latLonType = {
  latitude: number
  longitude: number
}

type latLonStateType = {
  lat: number
  lon: number
  set: boolean
}

type RestaurantFormProps = {
  text: string
  className?: string
  market: string
}

/**
 * Restaurant locator component. Posts to existing website, we have no control over this API.
 *
 * @function
 * @param       {string} text              The text of the link.
 * @param       {string} className         An optional class to add to the existing component class attribute.
 * @constructor
 */
const RestaurantForm = ({
  text = '',
  className = '',
  market,
}: RestaurantFormProps) => {
  const [coords, setCoords] = useState<latLonStateType>({
    lat,
    lon,
    set: false,
  })

  const form = useRef<HTMLFormElement>(null)

  function success(coords: { coords: latLonType }) {
    setCoords({
      lat: coords.coords.latitude,
      lon: coords.coords.longitude,
      set: true,
    })
  }

  useEffect(() => {
    if (coords.set && form.current) {
      form.current.submit()
    }
  }, [coords])

  return (
    <form
      action={`https://www.nandos${
        market === 'UK' ? '.co.uk' : '.ie'
      }/eat/restaurants/search#results`}
      method="get"
      ref={form}
      className={className}
      role="menuitem"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        if (coords.set && form.current) {
          form.current.submit()
          return
        }

        if (typeof navigator !== undefined) {
          navigator.geolocation.getCurrentPosition(success)
        }
      }}
    >
      <label
        id={`search-${inst < 20 ? ++inst : (inst = 0)}`}
        htmlFor={`edit-search-${inst}`}
      >
        {text}
      </label>
      <input
        type="text"
        id={`edit-search-${inst}`}
        name="search"
        placeholder={market === 'UK' ? 'e.g. W5 3NU' : 'e.g. Dundrum'}
        aria-labelledby={`search-${inst}`}
      />
      <button
        type="button"
        title="Please allow us access to your location"
        className="geo"
        onClick={() => {
          if (typeof navigator !== undefined) {
            navigator.geolocation.getCurrentPosition(success)
          }
        }}
      />
      <input type="hidden" name="lat" value={coords.lat} />
      <input type="hidden" name="lon" value={coords.lon} />
      <input
        id={`edit-submit${inst}`}
        type="submit"
        name="op"
        title="Search for a Nando’s"
        value="Go"
      />
    </form>
  )
}

type RestaurantLocatorProps = {
  inList: boolean
  text: string
  market: string
}

export const RestaurantLocator = ({
  inList = false,
  ...props
}: RestaurantLocatorProps) => {
  if (inList) {
    return (
      <li role="none">
        <RestaurantForm {...props} />
      </li>
    )
  }

  return <RestaurantForm {...props} />
}
