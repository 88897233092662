import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** ID for the given address as a string */
  AddressId: { input: string; output: string; }
  /** A UTC date time in RFC3339 format. */
  DateTime: { input: string; output: string; }
  /** ID for the given job as a string */
  JobId: { input: string; output: string; }
  /**
   * ISO 8601 calendar date without timezone.
   * Format: %Y-%m-%d
   *
   * # Examples
   *
   * * `1994-11-13`
   * * `2000-02-24`
   */
  NaiveDate: { input: string; output: string; }
  /** ID for the given user as a string */
  UserId: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Country;
  createdAt: Scalars['DateTime']['output'];
  deliveryInstructions: Scalars['String']['output'];
  id: Scalars['AddressId']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  line1: Scalars['String']['output'];
  line2: Scalars['String']['output'];
  long?: Maybe<Scalars['Float']['output']>;
  postcode: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkNewJobInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiry: Scalars['DateTime']['input'];
  expiryReminderSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  identifier: Scalars['String']['input'];
  schoolEndYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  schoolStartYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  type: JobType;
  userId: Scalars['UserId']['input'];
};

export type BulkUpdateDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UserId']['input'];
  idpId?: InputMaybe<Scalars['String']['input']>;
  idpIdOkta?: InputMaybe<Scalars['String']['input']>;
  loyaltyId?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['DateTime']['input']>;
  mfaFactors?: InputMaybe<Array<InputFactor>>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Country {
  RepublicOfIreland = 'REPUBLIC_OF_IRELAND',
  UnitedKingdom = 'UNITED_KINGDOM'
}

export type DeleteAddressResponse = {
  __typename?: 'DeleteAddressResponse';
  id: Scalars['AddressId']['output'];
  /**
   * This field gives the id of the primary address as it may have changed
   * if the primary address was the one being deleted.
   */
  primaryAddressId?: Maybe<Scalars['AddressId']['output']>;
};

export type EditAddressInput = {
  city: Scalars['String']['input'];
  country: Country;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['AddressId']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postcode: Scalars['String']['input'];
};

export type EditJobInput = {
  disabled: Scalars['Boolean']['input'];
  expiry: Scalars['DateTime']['input'];
  expiryReminderSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['JobId']['input'];
  identifier: Scalars['String']['input'];
  schoolEndYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  schoolStartYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  type: JobType;
};

export type Factor = {
  __typename?: 'Factor';
  created: Scalars['DateTime']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  oktaId: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: FactorStatus;
  type: FactorType;
};

export enum FactorStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export enum FactorType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type FindJobsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  identifier: Scalars['String']['input'];
  type: JobType;
};

export type FindUserProfilesInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  loyaltyId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UserId']['input']>;
};

export type InputFactor = {
  created: Scalars['DateTime']['input'];
  lastUpdated?: InputMaybe<Scalars['DateTime']['input']>;
  oktaId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  status: FactorStatus;
  type: FactorType;
};

export type Job = {
  __typename?: 'Job';
  createdAt: Scalars['DateTime']['output'];
  /** Disabled is true if the job is no longer active */
  disabled: Scalars['Boolean']['output'];
  /** When the job will expire and no longer be active */
  expiry: Scalars['DateTime']['output'];
  /** The time of which a expiry reminder was last sent. */
  expiryReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID for this job */
  id: Scalars['JobId']['output'];
  /** Job identifier, for example an NHS email address if the job type is NHS. */
  identifier: Scalars['String']['output'];
  schoolEndYear?: Maybe<Scalars['NaiveDate']['output']>;
  schoolStartYear?: Maybe<Scalars['NaiveDate']['output']>;
  /** The job type, e.g. NHS */
  type: JobType;
  updatedAt: Scalars['DateTime']['output'];
  userProfile: UserProfile;
};

export enum JobType {
  Hse = 'HSE',
  Nandoca = 'NANDOCA',
  Nhs = 'NHS',
  Partner = 'PARTNER',
  Student = 'STUDENT'
}

export type MarkAsPrimaryResponse = {
  __typename?: 'MarkAsPrimaryResponse';
  currentPrimaryAddressId: Scalars['AddressId']['output'];
  previousPrimaryAddressId?: Maybe<Scalars['AddressId']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addNewAddress: Address;
  addNewJob: Scalars['JobId']['output'];
  authorizeJob: Scalars['JobId']['output'];
  bulkAddNewJobs: Array<Scalars['JobId']['output']>;
  bulkCreateUserProfiles: Array<Scalars['UserId']['output']>;
  bulkUpdateUserProfiles: Array<Scalars['UserId']['output']>;
  createUserProfile: Scalars['UserId']['output'];
  deleteAddress: DeleteAddressResponse;
  deleteJob: Scalars['JobId']['output'];
  deleteUserProfile: Scalars['UserId']['output'];
  editAddress: Address;
  editJob: Scalars['JobId']['output'];
  getOrCreateUserProfile: UserProfile;
  markAddressAsPrimary: MarkAsPrimaryResponse;
  optOut: OptOutResponse;
  updateUserProfile: Scalars['UserId']['output'];
};


export type MutationAddNewAddressArgs = {
  address: NewAddressInput;
  userId: Scalars['UserId']['input'];
};


export type MutationAddNewJobArgs = {
  job: NewJobInput;
  noUpsert?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['UserId']['input'];
};


export type MutationAuthorizeJobArgs = {
  token: Scalars['String']['input'];
};


export type MutationBulkAddNewJobsArgs = {
  jobs: Array<BulkNewJobInput>;
};


export type MutationBulkCreateUserProfilesArgs = {
  profiles: Array<NewDetailsInput>;
};


export type MutationBulkUpdateUserProfilesArgs = {
  profiles: Array<BulkUpdateDetailsInput>;
};


export type MutationCreateUserProfileArgs = {
  details: NewDetailsInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['AddressId']['input'];
  userId: Scalars['UserId']['input'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['JobId']['input'];
  userId: Scalars['UserId']['input'];
};


export type MutationDeleteUserProfileArgs = {
  userId: Scalars['UserId']['input'];
};


export type MutationEditAddressArgs = {
  input: EditAddressInput;
  userId: Scalars['UserId']['input'];
};


export type MutationEditJobArgs = {
  input: EditJobInput;
  userId: Scalars['UserId']['input'];
};


export type MutationGetOrCreateUserProfileArgs = {
  details: NewDetailsInput;
};


export type MutationMarkAddressAsPrimaryArgs = {
  id: Scalars['AddressId']['input'];
  userId: Scalars['UserId']['input'];
};


export type MutationOptOutArgs = {
  encodedEmail: Scalars['String']['input'];
};


export type MutationUpdateUserProfileArgs = {
  details: UpdateDetailsInput;
  userId: Scalars['UserId']['input'];
};

export type NewAddressInput = {
  city: Scalars['String']['input'];
  country: Country;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postcode: Scalars['String']['input'];
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Country>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  idpId?: InputMaybe<Scalars['String']['input']>;
  idpIdOkta?: InputMaybe<Scalars['String']['input']>;
  loyaltyId?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['DateTime']['input']>;
  mfaFactors?: InputMaybe<Array<InputFactor>>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['UserId']['input']>;
};

export type NewJobInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiry: Scalars['DateTime']['input'];
  expiryReminderSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  identifier: Scalars['String']['input'];
  schoolEndYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  schoolStartYear?: InputMaybe<Scalars['NaiveDate']['input']>;
  type: JobType;
};

export enum OptInStatus {
  None = 'NONE',
  OptIn = 'OPT_IN',
  OptOut = 'OPT_OUT'
}

export type OptOutResponse = {
  __typename?: 'OptOutResponse';
  optOutType: OptOutType;
  success: Scalars['Boolean']['output'];
};

export enum OptOutType {
  Firestore = 'FIRESTORE',
  LeadsCapture = 'LEADS_CAPTURE'
}

export type Query = {
  __typename?: 'Query';
  findJobs: Array<Job>;
  findUserProfiles: Array<UserProfile>;
};


export type QueryFindJobsArgs = {
  by: FindJobsInput;
};


export type QueryFindUserProfilesArgs = {
  by: FindUserProfilesInput;
};

export type UpdateDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Country>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  idpId?: InputMaybe<Scalars['String']['input']>;
  idpIdOkta?: InputMaybe<Scalars['String']['input']>;
  loyaltyId?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['DateTime']['input']>;
  mfaFactors?: InputMaybe<Array<InputFactor>>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  addresses: Array<Address>;
  birthday?: Maybe<Scalars['DateTime']['output']>;
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime']['output'];
  docCreatedAt: Scalars['DateTime']['output'];
  docUpdatedAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UserId']['output'];
  idpId?: Maybe<Scalars['String']['output']>;
  idpIdOkta?: Maybe<Scalars['String']['output']>;
  jobs: Array<Job>;
  loyaltyId?: Maybe<Scalars['String']['output']>;
  marketingOptIn?: Maybe<Scalars['DateTime']['output']>;
  marketingOptInStatus: OptInStatus;
  marketingOptInUpdated?: Maybe<Scalars['DateTime']['output']>;
  mfaFactors: Array<Factor>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  termsAndConditions?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserProfileJobsArgs = {
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserProfileMfaFactorsArgs = {
  status?: InputMaybe<FactorStatus>;
  type?: InputMaybe<FactorType>;
};

export type AddNewJobMutationVariables = Exact<{
  userId: Scalars['UserId']['input'];
  job: NewJobInput;
}>;


export type AddNewJobMutation = { __typename?: 'Mutation', addNewJob: string };

export type AuthorizeJobMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type AuthorizeJobMutation = { __typename?: 'Mutation', authorizeJob: string };

export type EditJobMutationVariables = Exact<{
  userId: Scalars['UserId']['input'];
  input: EditJobInput;
}>;


export type EditJobMutation = { __typename?: 'Mutation', editJob: string };

export type OptOutMutationVariables = Exact<{
  encodedEmail: Scalars['String']['input'];
}>;


export type OptOutMutation = { __typename?: 'Mutation', optOut: { __typename?: 'OptOutResponse', success: boolean, optOutType: OptOutType } };

export type FindUserJobsQueryVariables = Exact<{
  userId: Scalars['UserId']['input'];
}>;


export type FindUserJobsQuery = { __typename?: 'Query', findUserProfiles: Array<{ __typename?: 'UserProfile', id: string, jobs: Array<{ __typename?: 'Job', id: string, identifier: string, expiry: string, type: JobType, disabled: boolean }> }> };


export const AddNewJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addNewJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"job"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewJobInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addNewJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"job"},"value":{"kind":"Variable","name":{"kind":"Name","value":"job"}}}]}]}}]} as unknown as DocumentNode;

export function useAddNewJobMutation() {
  return Urql.useMutation<AddNewJobMutation, AddNewJobMutationVariables>(AddNewJobDocument);
};
export const AuthorizeJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"authorizeJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authorizeJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}]}]}}]} as unknown as DocumentNode;

export function useAuthorizeJobMutation() {
  return Urql.useMutation<AuthorizeJobMutation, AuthorizeJobMutationVariables>(AuthorizeJobDocument);
};
export const EditJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"editJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditJobInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;

export function useEditJobMutation() {
  return Urql.useMutation<EditJobMutation, EditJobMutationVariables>(EditJobDocument);
};
export const OptOutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"optOut"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"encodedEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"optOut"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"encodedEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"encodedEmail"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"optOutType"}}]}}]}}]} as unknown as DocumentNode;

export function useOptOutMutation() {
  return Urql.useMutation<OptOutMutation, OptOutMutationVariables>(OptOutDocument);
};
export const FindUserJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"findUserJobs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findUserProfiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"by"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"jobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"expiry"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"disabled"}}]}}]}}]}}]} as unknown as DocumentNode;

export function useFindUserJobsQuery(options: Omit<Urql.UseQueryArgs<FindUserJobsQueryVariables>, 'query'>) {
  return Urql.useQuery<FindUserJobsQuery, FindUserJobsQueryVariables>({ query: FindUserJobsDocument, ...options });
};