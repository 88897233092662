import { ReactNode, useEffect, useState } from 'react'
import {
  cacheExchange,
  createClient,
  fetchExchange,
  Provider as UrqlProvider,
} from 'urql'
import { persistedFetchExchange } from '@urql/exchange-persisted-fetch'

import { CountryProvider } from '../utils/country'
import { graphqlUrl } from '../utils/graphqlClient'
import { OriginProvider } from '../utils/origin'
import { UserProvider } from '../utils/user'
import { AuthProvider } from './AuthProvider'

type Props = {
  host: string
  origin: string
  children: ReactNode
}

export const Authenticated = ({ host, origin, children }: Props) => {
  return (
    <OriginProvider origin={origin}>
      <CountryProvider host={host}>
        <AuthProvider>
          {({ auth_token }) => (
            <Urql auth_token={auth_token}>
              <UserProvider auth_token={auth_token}>{children}</UserProvider>
            </Urql>
          )}
        </AuthProvider>
      </CountryProvider>
    </OriginProvider>
  )
}

type UrqlProps = {
  auth_token: string | null
  children: ReactNode
}

const Urql = ({ auth_token, children }: UrqlProps) => {
  const [{ client, token }, setClient] = useState(() => buildClient(auth_token))

  useEffect(() => {
    // Avoid recreating client if token hasn't changed
    if (auth_token && auth_token !== token) {
      setClient(() => buildClient(auth_token))
    }
  }, [auth_token])

  return <UrqlProvider value={client}>{children}</UrqlProvider>
}

const buildClient = (token: string | null) => ({
  token,
  client: createClient({
    url: graphqlUrl(process.env.NEXT_PUBLIC_PROFILE_API_V2_URL || ''),
    exchanges: [cacheExchange, persistedFetchExchange(), fetchExchange],
    fetchOptions: {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    },
  }),
})
