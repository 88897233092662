import React, { useLayoutEffect, useState } from 'react'
React.useLayoutEffect = React.useEffect 
const ACTIVE_CLASS = ' active'

/**
 * Button for mobile navigation.
 *
 * @function
 * @param       {boolean} inList           If we are already in a list, this will be true and force the omission of the opening ul tag.
 * @param       {string} text              The text of the link.
 * @param       {string} className         An optional class to add to the existing component class attribute.
 * @param       {function} setOpenMobileMenu useState function to set if the menu is opened using the burger menu button for mobile
 * @param       {boolean} openMobileMenu   Is true if the menu opener button clicked was the burger menu button for mobile.
 * @constructor
 */

export type MobileNavigationProps = {
  inList: boolean
  text: string
  className: string
  openMobileMenu: boolean
  setOpenMobileMenu: (value: boolean) => void
}

export const MobileNavigation = ({
  inList,
  text,
  className,
  setOpenMobileMenu,
  openMobileMenu,
}: MobileNavigationProps) => {
  const [currentClassName, setCurrentClassName] = useState('')

  function Button() {
    useLayoutEffect(() => {
      const newClassName = [
        className,
        openMobileMenu ? ACTIVE_CLASS : null,
      ].join(' ')

      if (newClassName !== currentClassName) {
        setCurrentClassName(newClassName)
      }
    }, [openMobileMenu])

    return (
      <button
        title="Open page navigation"
        onClick={() => {
          setOpenMobileMenu(!openMobileMenu)
        }}
        className={currentClassName}
      >
        {text}
      </button>
    )
  }
  if (inList) {
    return (
      <li role="none">
        <Button />
      </li>
    )
  }

  return <Button />
}
