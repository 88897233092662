import React, { ReactNode } from 'react'

const cardClasses =
  'group bg-white border-2 border-transparent bg-clip-padding rounded shadow-customBlack !outline-none transition-shadow duration-300 hover:shadow-customBlue focus:shadow-customBlue focus:border-blue/30 active:border-blue/30 active:shadow-customBlue'

export type CardProps = {
  title?: string
  children: ReactNode
  footer?: ReactNode
  url?: string
  newTab?: boolean
  withPadding?: boolean
  onClick?: () => void
  onHover?: () => void
}

type ButtonProps = {
  children: ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
}

const Button = ({ children, onClick, ...props }: ButtonProps) => (
  <button className={cardClasses} type="button" onClick={onClick} {...props}>
    {children}
  </button>
)

type AnchorProps = {
  children: ReactNode
  url: string
  newTab?: boolean
  onMouseEnter?: () => void
}

const Anchor = ({
  children,
  url,
  newTab,
  ...props
}: AnchorProps): JSX.Element => (
  <a
    className={cardClasses}
    href={url}
    target={newTab ? '_blank' : ''}
    {...props}
  >
    {children}
  </a>
)

const AnchorOrButton = ({
  url,
  newTab,
  onClick,
  onMouseEnter,
  children,
}: ButtonProps & AnchorProps) => {
  if (url) {
    return (
      <Anchor url={url} newTab={newTab} onMouseEnter={onMouseEnter}>
        {children}
      </Anchor>
    )
  }

  return onClick ? (
    <Button onClick={onClick} onMouseEnter={onMouseEnter}>
      {children}
    </Button>
  ) : (
    <div className={cardClasses} onMouseEnter={onMouseEnter}>
      {children}
    </div>
  )
}

export const Card = ({
  title = '',
  footer,
  children,
  url = '',
  newTab = false,
  withPadding = true,
  onClick,
  onHover,
  ...props
}: CardProps) => (
  <div className="flex flex-col" {...props}>
    {title ? <h2 className="mb-3 text-base font-medium">{title}</h2> : null}
    <AnchorOrButton
      url={url}
      newTab={newTab}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      <div className={withPadding ? 'p-3 py-8 sm:p-6' : ''}>{children}</div>
      {footer && (
        <div className="p-4 text-center border-t border-gray-200">{footer}</div>
      )}
    </AnchorOrButton>
  </div>
)
