import React, { ReactNode } from 'react'
import { classes } from './utils/classes'

export type PanelProps = {
  className?: string
  children: ReactNode
}

export const Panel = ({ className = '', children }: PanelProps) => (
  <section
    className={classes(
      'flex flex-col bg-white border-t border-b border-gray-500 xs:border xs:rounded-sm',
      className
    )}
  >
    {children}
  </section>
)

export type PanelHeaderProps = {
  title: string | ReactNode
  action?: ReactNode
}

export const PanelHeader = ({ title, action }: PanelHeaderProps) => (
  <div className="flex items-center justify-between p-4 border-b border-gray-500">
    <h2 className="text-xl ">{title}</h2>
    {action}
  </div>
)

export type PanelContentProps = {
  withBottomBorder?: boolean
  withPadding?: boolean
  children: ReactNode
}

export const PanelContent = ({
  withBottomBorder,
  withPadding = true,
  children,
}: PanelContentProps) => (
  <div
    className={classes(
      withPadding ? 'p-4' : '',
      'break-words',
      withBottomBorder ? 'border-b border-gray-200' : ''
    )}
  >
    {children}
  </div>
)
