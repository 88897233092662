import { EnrolledFactor, FactorType, SupportedFactor } from '../utils/mfa'

export interface GetMeResponse {
  id: string
  status: string
  created: string
  lastLogin: string
  passwordChanged: string
  provider: {
    type: string
    name: string
  }
}

export async function getMe(
  authApiUrl: string,
  auth_token: string
): Promise<GetMeResponse> {
  const res = await fetch(`${authApiUrl}/me`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}

export async function changePassword(
  authApiUrl: string,
  auth_token: string,
  oldPassword: string,
  newPassword: string
): Promise<void> {
  const res = await fetch(`${authApiUrl}/password/change`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  })

  if (res.status != 200) {
    throw await res.json()
  }
}

export async function createPassword(
  authApiUrl: string,
  auth_token: string,
  newPassword: string
): Promise<void> {
  const res = await fetch(`${authApiUrl}/password/create`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      newPassword,
    }),
  })

  if (res.status != 200) {
    throw await res.json()
  }
}

export interface PasswordRequirements {
  minLength: number
  minLowerCase: number
  minUpperCase: number
  minNumber: number
  minSymbol: number
  excludeUsername: boolean
  dictionary: {
    common: {
      exclude: boolean
    }
  }
  excludeAttributes: any[]
}

interface GetConfigResponse {
  passwordPolicy: {
    complexity: PasswordRequirements
  }
}

export async function getConfig(
  authApiUrl: string
): Promise<GetConfigResponse> {
  const res = await fetch(`${authApiUrl}/configuration`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}

export interface GetFactorsRespponse {
  enrolledFactors: EnrolledFactor[]
  supportedFactors: SupportedFactor[]
}

export async function getFactors(
  authApiUrl: string,
  auth_token: string
): Promise<GetFactorsRespponse> {
  const res = await fetch(`${authApiUrl}/factors`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}

export async function deleteFactor(
  authApiUrl: string,
  auth_token: string,
  id: string
): Promise<void> {
  const res = await fetch(`${authApiUrl}/factors/${id}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
}

export async function enrolFactor(
  authApiUrl: string,
  auth_token: string,
  type: FactorType,
  phoneNumber?: string
): Promise<EnrolledFactor> {
  const res = await fetch(`${authApiUrl}/factors/enrol`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      factorType: type,
      activate: false,
      phoneNumber,
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}

export async function activateFactor(
  authApiUrl: string,
  auth_token: string,
  id: string,
  passCode: string
): Promise<EnrolledFactor> {
  const res = await fetch(`${authApiUrl}/factors/activate`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${auth_token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      id,
      passCode,
    }),
  })
  if (res.status != 200) {
    const errorMessage = await res.text()

    throw new Error(
      `Error response from auth api (${res.status}): ${errorMessage}`
    )
  }
  return res.json()
}
