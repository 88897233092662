import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import { ErrorMessage } from './components/form/ErrorMessage'
import { CheckboxField } from './components/form/fields/CheckboxField'
import { CountryRadioField } from './components/form/fields/CountryRadioField'
import { TextAreaField } from './components/form/fields/TextAreaField'
import { TextField } from './components/form/fields/TextField'
import { loadedAnimationDuration, Loader } from './components/Loader'
import {
  Modal,
  ModalBodyWithTitle,
  BaseModalProps,
  exitAnimationDuration,
  ModalButtons,
} from './components/Modal'
import { Country } from './generated/current-user-graphql'
import { AddressFields } from './savedAddresses/address'
import { timeout } from './utils/timeout'
import { useFormState, FormState, SetFormState } from './utils/useFormState'

export interface AddNewAddressProps extends BaseModalProps {
  onSave: (addr: AddressFields) => Promise<void>
  canMakePrimaryAddress: boolean
}

export const AddNewAddressModal = ({
  isOpen,
  canMakePrimaryAddress,
  onClose,
  onSave,
}: AddNewAddressProps) => (
  <AddressModal
    title="Add an address"
    {...{ isOpen, canMakePrimaryAddress, onClose, onSave }}
  />
)

export interface EditAddressProps extends BaseModalProps {
  initialValue: AddressFields
  canMakePrimaryAddress: boolean
  onSave: (addr: AddressFields) => Promise<void>
}

export const EditAddressModal = ({
  isOpen,
  canMakePrimaryAddress,
  onClose,
  onSave,
  initialValue,
}: EditAddressProps) => (
  <AddressModal
    title="Edit address"
    {...{
      isOpen,
      canMakePrimaryAddress,
      onClose,
      onSave,
      initialValue,
    }}
  />
)

type FormInputs = {
  line1: string
  line2: string
  city: string
  country: 'GB' | 'IE'
  postcode: string
  deliveryInstructions: string
  primary: boolean
}

interface AddressModalProps extends BaseModalProps {
  onSave: (addr: AddressFields) => Promise<void>
  initialValue?: AddressFields
  title: string
  canMakePrimaryAddress: boolean
}

const AddressModal = ({
  isOpen,
  initialValue,
  canMakePrimaryAddress,
  title,
  onClose,
  onSave,
}: AddressModalProps) => {
  const [state, setState] = useFormState()
  return (
    <Modal isOpen={isOpen} onClose={onClose} blur animationKey={String(state)}>
      <Body
        {...{
          title,
          initialValue,
          canMakePrimaryAddress,
          onSave,
          onClose,
          state,
          setState,
        }}
      />
    </Modal>
  )
}

interface BodyProps {
  title: string
  initialValue?: AddressFields
  canMakePrimaryAddress: boolean
  onSave: (addr: AddressFields) => Promise<void>
  onClose: () => void
  state: FormState
  setState: SetFormState
}

const countryToString = (c: Country): 'GB' | 'IE' => {
  switch (c) {
    case Country.UnitedKingdom:
      return 'GB'
    case Country.RepublicOfIreland:
      return 'IE'
  }
}

const Body = ({
  title,
  initialValue,
  canMakePrimaryAddress,
  onSave,
  onClose,
  state,
  setState,
}: BodyProps) => {
  const [hasError, setError] = useState(false)

  const formMethods = useForm<FormInputs>({
    defaultValues: {
      ...initialValue,
      country: initialValue ? countryToString(initialValue.country) : undefined,
    },
  })

  const { handleSubmit } = formMethods

  const handleUpdate = useCallback(
    async (update: () => Promise<void>) => {
      setState(FormState.Submitting)
      setError(false)

      try {
        await update()
        setState(FormState.Submitted)

        // wait for tick animation
        await timeout(loadedAnimationDuration)

        onClose()

        // wait for modal to close
        await timeout(exitAnimationDuration)
      } catch (e) {
        setError(true)
      }
      setState(FormState.AwaitingSubmission)
    },
    [setState, setError, onClose]
  )

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      await handleUpdate(() =>
        onSave({
          ...data,
          country:
            data.country === 'IE'
              ? Country.RepublicOfIreland
              : Country.UnitedKingdom,
        })
      )
    }),
    [handleSubmit, handleUpdate, onSave]
  )

  const onCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <>
      <ModalBodyWithTitle title={title} showCloseButton>
        <FormProvider {...formMethods}>
          <Form
            {...{
              state,
              hasError,
              canMakePrimaryAddress,
              onSubmit,
            }}
          />
        </FormProvider>
      </ModalBodyWithTitle>
      {state === FormState.AwaitingSubmission ? (
        <ModalButtons
          primaryCTAText="save"
          onPrimaryCTAClick={onSubmit}
          secondaryCTAText="cancel"
          onSecondaryCTAClick={onCancel}
        />
      ) : null}
    </>
  )
}

type FormProps = {
  state: FormState
  canMakePrimaryAddress: boolean
  hasError: boolean
  onSubmit: () => void
}

const Form = ({
  state,
  hasError,
  canMakePrimaryAddress,
  onSubmit,
}: FormProps) => {
  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<FormInputs>()

  const submit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit()
  }

  const country = watch('country')

  useRevalidatePostcodeOnCountryChange(country, trigger)

  switch (state) {
    case FormState.Submitting:
      return <LoadingSpinner />

    case FormState.Submitted:
      return <LoadingSpinner loaded />

    case FormState.AwaitingSubmission:
    default:
      return (
        <form onSubmit={submit}>
          {hasError ? (
            <div className="mb-4">
              <ErrorMessage big>
                There was an error, please try again.
              </ErrorMessage>
            </div>
          ) : null}

          <div className="mb-4">
            <TextField
              label="Address line 1"
              error={errors.line1 ? 'Please enter an address' : ''}
              {...register('line1', { required: true })}
            />
          </div>

          <div className="mb-4">
            <TextField
              label="Address line 2 (optional)"
              error={errors.line2 ? 'Please enter an address' : ''}
              {...register('line2')}
            />
          </div>

          <div className="mb-4">
            <TextField
              label="City"
              error={errors.city ? 'Please enter a city' : ''}
              {...register('city', { required: true })}
            />
          </div>

          <div className="mb-4">
            <TextField
              label={country === 'IE' ? 'Eircode' : 'Postcode'}
              error={
                errors.postcode
                  ? country === 'IE'
                    ? 'Please enter an Eircode'
                    : 'Please enter a postcode'
                  : ''
              }
              {...register('postcode', {
                required: true,
                pattern:
                  country === 'IE'
                    ? /\s*(?:^[ac-fhknprtv-yAC-FHKNPRTV-Y][0-9]{2}|[dD]6[wW])[ -]?[0-9ac-fhknprtv-yAC-FHKNPRTV-Y]{4}/
                    : /\s*[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}/,
              })}
            />
          </div>

          <div className="mb-4">
            <CountryRadioField
              register={() => register('country', { required: true })}
              error={errors.country ? 'Please select a country' : ''}
            />
          </div>

          <div className="mb-4">
            <TextAreaField
              label="Delivery instructions (optional)"
              placeholder="e.g. Leave order in step and knock. Do not include any allergy information here."
              error={
                errors.deliveryInstructions
                  ? 'Please enter delivery instructions'
                  : ''
              }
              {...register('deliveryInstructions')}
            />
          </div>

          {canMakePrimaryAddress ? (
            <div className="flex py-4 mb-4">
              <CheckboxField
                label="Make this your primary address?"
                {...register('primary')}
              />
            </div>
          ) : null}
          <input type="submit" hidden />
        </form>
      )
  }
}

const useRevalidatePostcodeOnCountryChange = (
  country: string,
  trigger: (field: 'postcode') => void
) => {
  useEffect(() => {
    if (country) trigger('postcode')
  }, [country, trigger])
}

const LoadingSpinner = ({ loaded = false }: { loaded?: boolean }) => (
  <div className="flex items-center justify-center flex-1 pt-4 mb-8">
    <Loader loaded={loaded} />
  </div>
)
