import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { ErrorMessage, ErrorMessageSpacer } from '../../form/ErrorMessage'
import { Label } from '../Label'
import { TextInput } from '../inputs/TextInput'

export type TextFieldProps = {
  label: string
  type?: 'text' | 'email' | 'search'
  name: string
  placeholder?: string
  inputMode?: 'text' | 'email'
  error?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      type = 'text',
      name,
      placeholder,
      inputMode = 'text',
      error,
      onChange,
      onBlur,
    },
    ref
  ) => {
    const id = `field-${name}`

    return (
      <>
        <Label forId={id}>{label}</Label>
        <TextInput
          invalid={Boolean(error)}
          {...{
            ref,
            type,
            id,
            name,
            onChange,
            onBlur,
            placeholder,
            inputMode,
          }}
        />
        {error ? <ErrorMessage>{error}</ErrorMessage> : <ErrorMessageSpacer />}
      </>
    )
  }
)
