import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import { classes } from '../../../utils/classes'
import css from './Radio.module.scss'

export type RadioProps = {
  name: string
  value: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ name, value, onChange, onBlur }, ref) => (
    <input
      type="radio"
      className={classes(
        'bg-white border border-gray-600 w-5 h-5 rounded-full outline-none appearance-none hover:border-gray-900',
        css.radio
      )}
      {...{ ref, name, value, onChange, onBlur }}
    />
  )
)
