import { useEffect, useState } from 'react'
import { setConfigurationAPIURL, isEnabled } from '@nandosuk/configuration-api'

export const useConfiguration = (key: string) => {
  const [enabled, setEnabled] = useState(false)
  const apiUrl = process.env.NEXT_PUBLIC_CONFIG_API_URL || ''
  useEffect(() => {
    const getConfiguration = async () => {
      setConfigurationAPIURL(apiUrl)
      const bool = await isEnabled(key, 'profile-ui')
      setEnabled(bool)
    }
    getConfiguration()
  }, [apiUrl])

  return enabled
}
