import React, { ReactNode, useContext } from 'react'
import { useRouter } from 'next/router'

export type Country = 'GB' | 'IE'

const CountryContext = React.createContext<Country>('GB')

type Props = {
  host: string
  children: ReactNode
}

export const CountryProvider = ({ host, children }: Props) => {
  const router = useRouter()

  const fromUrl = router.query['feat-country']

  const country =
    fromUrl === 'GB' || fromUrl === 'IE' ? fromUrl : countryFromHost(host)

  return (
    <CountryContext.Provider value={country}>
      {children}
    </CountryContext.Provider>
  )
}

export const useCountry = () => {
  const country = useContext(CountryContext)

  return country
}

export const countryFromHost = (host: string): Country => {
  switch (host) {
    case 'www.nandos.ie':
    case 'nandos.ie':
    case 'preview.nandos.ie': {
      return 'IE'
    }

    default: {
      return 'GB'
    }
  }
}

export const isCountry = (country: any): country is Country => {
  return country === 'GB' || country === 'IE'
}
