import { classes } from '../../utils/classes'

export type LabelProps = {
  forId: string
  children: string
  bottomMargin?: boolean
}

export const Label = ({ forId, children, bottomMargin = true }: LabelProps) => (
  <label
    htmlFor={forId}
    className={classes('block font-medium', bottomMargin ? 'mb-1' : '')}
  >
    {children}
  </label>
)
