import { createContext, useContext, ReactNode } from 'react'

const OriginContext = createContext('')

type ProviderProps = {
  origin: string
  children: ReactNode
}

export const OriginProvider = ({ origin, children }: ProviderProps) => (
  <OriginContext.Provider value={origin}>{children}</OriginContext.Provider>
)

export const useOrigin = (): string => {
  const origin = useContext(OriginContext)
  return origin
}
