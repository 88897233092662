import React from 'react'
import { Button } from './components/buttons/Button'
import { BinCircled } from './icons/BinCircled'
import { Tick } from './icons/Tick'

export interface FactorItemProps {
  testIdSuffix: string
  label: string
  email?: string
  phoneNumber?: string
  isActive: boolean
  canDelete: boolean
  onSetup: () => void
  onDelete: () => void
  onUpdate: () => void
}

const FactorItem = ({
  testIdSuffix,
  label,
  email = '',
  phoneNumber = '',
  isActive,
  canDelete,
  onSetup,
  onDelete,
  onUpdate,
}: FactorItemProps) => {
  return (
    <div className="grid md:grid-cols-mfa-item grid-cols-mfa-item-sm items-center md:mb-1 mb-4">
      <div className="font-medium md:col-span-1 col-span-2">{label}</div>
      <div
        className="break-words data-hj-suppress"
        data-testid={'mfa-factor-' + testIdSuffix}
      >
        {email || phoneNumber || '-'}
        {isActive ? (
          <div className="inline-block align-middle ml-2 mb-1">
            <Tick />
          </div>
        ) : null}
      </div>
      <div className="ml-2 text-right">
        {isActive ? (
          <>
            <Button
              type="button"
              secondary
              secondaryClasses="bg-transparent text-blueButton text-sm underline break-normal !w-auto"
              upperCase={false}
              onClick={onUpdate}
              testId={'mfa-update-' + testIdSuffix}
            >
              Update
            </Button>
            {canDelete ? (
              <div
                className="inline-block align-middle ml-2 cursor-pointer"
                data-testid={'mfa-delete-' + testIdSuffix}
                onClick={onDelete}
              >
                <BinCircled />
              </div>
            ) : null}
          </>
        ) : (
          <Button
            type="button"
            secondary
            secondaryClasses="bg-transparent text-blueButton text-sm underline break-normal !w-auto"
            upperCase={false}
            onClick={onSetup}
            testId={'mfa-setup-' + testIdSuffix}
          >
            Enable
          </Button>
        )}
      </div>
    </div>
  )
}

export default FactorItem
