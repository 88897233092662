import { ErrorIcon } from '../../icons/Error'
import { classes } from '../../utils/classes'

export const ErrorMessage = ({
  big = false,
  children,
}: {
  big?: boolean
  children: string
}) => (
  <div
    className={classes(
      'flex items-center text-red-500',
      big ? 'p-2 bg-red-300 border-l-4 border-red-500' : 'mt-2'
    )}
  >
    <ErrorIcon large />
    <p className="mt-1 ml-2 text-red-700">{children}</p>
  </div>
)

export const ErrorMessageSpacer = () => <div className="h-4" />
