import React from 'react'

import { classes } from '../utils/classes'
import css from './Loader.module.scss'

type Size = 'small' | 'medium' | 'large'

export const loadedAnimationDuration = 2 * 1000

export type LoaderProps = {
  loaded: boolean
  size?: Size
}

const getSize = (size: Size) => {
  switch (size) {
    case 'small': {
      return 'w-12 h-12'
    }
    case 'medium': {
      return 'w-24 h-24'
    }
    case 'large': {
      return 'w-36 h-36'
    }
  }
}

const Spinner = ({ loaded = false, size = 'medium' }: LoaderProps) => (
  <svg
    data-testid="spinner"
    viewBox="0 0 100 100"
    className={classes(
      'opacity-100 transition-opacity duration-400 ease-linear',
      getSize(size),
      css.spinner,
      loaded ? '!opacity-0' : ''
    )}
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
        <stop stopColor="#89c400" offset="0%" stopOpacity="100%" />
        <stop stopColor="#89c400" offset="100%" stopOpacity="50%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="b">
        <stop stopColor="#89c400" offset="0%" stopOpacity="50%" />
        <stop stopColor="#89c400" offset="95%" stopOpacity="0%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      {/* for the rounded edge */}
      <circle cx="50" cy="4" r="4" fill="#89c400" />
      <path
        d="M 50 96 a 1 1, 0, 0, 1, 0 -92"
        fill="none"
        stroke="url(#a)"
        strokeWidth="8"
      />
      <path
        d="M 50 96 a 1 1, 0, 0, 0, 0 -92"
        fill="none"
        stroke="url(#b)"
        strokeWidth="8"
      />
    </g>
  </svg>
)

const Tick = ({ size }: { size: Size }) => (
  <svg
    data-testid="tick"
    className={classes(
      'absolute checkmark stroke-current text-green-400 br-50 rounded-full opacity-0',
      getSize(size),
      css.loader
    )}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <circle cx="50" cy="50" r="50" strokeWidth="16" fill="none" />
    <path
      className={css.loaderCheck}
      fill="none"
      d="M29 55 l10 10 32 -32"
      strokeWidth="8"
    />
  </svg>
)

export const Loader = ({ loaded = false, size = 'medium' }: LoaderProps) => (
  <>
    {loaded && <Tick size={size} />}
    <Spinner loaded={loaded} size={size} />
  </>
)
