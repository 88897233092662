import { useEffect, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import {
  DiscountType,
  DiscountTypeConfig,
  getDiscountTypeConfig,
} from '../api/verification'

export const useDiscountTypeConfig = (type: DiscountType) => {
  const [config, setConfig] = useState<DiscountTypeConfig | null>(null)
  const apiUrl = process.env.NEXT_PUBLIC_VERIFY_API_URL || ''
  useEffect(() => {
    const getConfig = async () => {
      try {
        const config = await getDiscountTypeConfig(apiUrl, type)
        setConfig(config)
      } catch (e) {
        console.error('failed to load configuration for discount type: ' + type)
        captureException(e)
      }
    }
    getConfig()
  }, [apiUrl, type])

  return config
}
