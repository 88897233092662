import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { captureException } from '@sentry/nextjs'
import { User } from './User'
import { Panel, PanelContent, PanelHeader } from './Panel'
import { P } from './components/typography'
import { useBooleanState } from './utils/useBooleanState'
import { useUser } from './utils/user'
import {
  EmailFactor,
  SMSFactor,
  getFactorList,
  deleteFactorAndUpdate,
  getDefaultEmailFactor,
  getDefaultSMSFactor,
  enrolEmailFactor,
  activateFactorAndUpdate,
  enrolSMSFactor,
  Factor,
} from './utils/mfa'
import FactorItem from './FactorItem'
import EmailFactorEnrolModal from './EmailFactorEnrolModal'
import EmailFactorUpdateModal from './EmailFactorUpdateModal'
import SMSFactorEnrolModal from './SMSFactorEnrolModal'
import { Loader } from './components/Loader'
import FactorDeleteModal from './FactorDeleteModal'
import { Alert } from './icons/Alert'

export type MFAManagementProps = {
  user: User
}

export const MFAManagement = ({ user }: MFAManagementProps) => {
  const authApiUrl = process.env.NEXT_PUBLIC_AUTH_API_URL || ''
  const { auth_token } = useUser()
  const [emailFactor, setEmailFactor] = useState<EmailFactor>(
    getDefaultEmailFactor(user)
  )
  const [smsFactor, setSmsFactor] = useState<SMSFactor>(
    getDefaultSMSFactor(user)
  )
  const [factorToDelete, setFactorToDelete] = useState<Factor | null>(null)
  const emailFactorModal = useBooleanState(false)
  const emailFactorUpdateModal = useBooleanState(false)
  const smsFactorModal = useBooleanState(false)
  const smsFactorUpdateModal = useBooleanState(false)
  const deleteFactorModal = useBooleanState(false)
  const [loading, setLoading] = useState(false)
  const hasNoFactorEnrolled = useMemo(
    () =>
      emailFactor.status !== 'ACTIVE' &&
      smsFactor.status !== 'ACTIVE' &&
      !loading,
    [emailFactor, smsFactor, loading]
  )
  const numOfActivteFactors = useMemo(
    () =>
      [emailFactor, smsFactor].filter((factor) => factor.status === 'ACTIVE')
        .length,
    [emailFactor, smsFactor]
  )

  const performDelete = useCallback(async () => {
    if (!factorToDelete) return
    const factorList = await deleteFactorAndUpdate(
      authApiUrl,
      auth_token,
      user,
      factorToDelete.id
    )
    if (factorList) {
      setEmailFactor(factorList.emailFactor)
      setSmsFactor(factorList.smsFactor)
    }
  }, [authApiUrl, auth_token, user, factorToDelete])

  const setupEmailFactor = useCallback(() => {
    emailFactorModal.enable()
  }, [emailFactorModal])

  const setupSMSFactor = useCallback(() => {
    smsFactorModal.enable()
  }, [smsFactorModal])

  const deleteEmailFactor = useCallback(() => {
    setFactorToDelete(emailFactor)
    deleteFactorModal.enable()
  }, [emailFactor, deleteFactorModal])

  const deleteSMSFactor = useCallback(() => {
    setFactorToDelete(smsFactor)
    deleteFactorModal.enable()
  }, [smsFactor, deleteFactorModal])

  const handleEmailFactorEnrolment = useCallback(async () => {
    const factor = await enrolEmailFactor(authApiUrl, auth_token, emailFactor)
    if (factor) {
      setEmailFactor(factor)
    }
    return factor
  }, [authApiUrl, auth_token, emailFactor])

  const handleSMSFactorEnrolment = useCallback(
    async (phoneNumber: string) => {
      const factor = await enrolSMSFactor(
        authApiUrl,
        auth_token,
        smsFactor,
        phoneNumber
      )
      if (factor) {
        if (factor.status === 'PENDING_ACTIVATION') {
          setSmsFactor(factor)
        } else if (factor.status === 'ACTIVE') {
          const factorList = await getFactorList(authApiUrl, auth_token, user)
          setEmailFactor(factorList.emailFactor)
          setSmsFactor(factorList.smsFactor)
        }
      }
      return factor
    },
    [authApiUrl, auth_token, smsFactor]
  )

  const handleFactorActivation = useCallback(
    async (factorId: string, passCode: string) => {
      const factorList = await activateFactorAndUpdate(
        authApiUrl,
        auth_token,
        user,
        factorId,
        passCode
      )
      if (factorList) {
        setEmailFactor(factorList.emailFactor)
        setSmsFactor(factorList.smsFactor)
      }
    },
    [authApiUrl, auth_token, user]
  )

  const updateEmailFactor = useCallback(() => {
    emailFactorUpdateModal.enable()
  }, [emailFactorUpdateModal])

  const updateSMSFactor = useCallback(() => {
    smsFactorUpdateModal.enable()
  }, [smsFactorUpdateModal])

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        const factorList = await getFactorList(authApiUrl, auth_token, user)
        setEmailFactor(factorList.emailFactor)
        setSmsFactor(factorList.smsFactor)
      } catch (e) {
        captureException(e)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [user, auth_token])

  if (!loading && !emailFactor.supported && !smsFactor.supported) {
    // No available factors (e.g. social customer without a password)
    return null
  }

  return (
    <div className="mt-6">
      <Panel className={hasNoFactorEnrolled ? '!border-orange' : undefined}>
        <PanelHeader
          title={
            <>
              {hasNoFactorEnrolled ? (
                <div className="inline-block align-middle mr-2 text-orange">
                  <Alert />
                </div>
              ) : null}
              Two-factor authentication
            </>
          }
        />
        <PanelContent>
          {hasNoFactorEnrolled ? (
            <P className="text-sm mb-4">
              You’re not currently using two-factor authentication, we advise
              you enable at least one extra method protect your account.
            </P>
          ) : null}
          <P className="text-sm text-gray-800 pb-4">
            Help protect your account from unauthorized access by requiring a
            second authentication method in addition to your password.
          </P>
          {loading ? (
            <div className="flex items-center justify-center flex-1">
              <Loader size="small" loaded={false} />
            </div>
          ) : null}
          {!loading &&
          emailFactor &&
          (emailFactor.supported || emailFactor.enrolled) ? (
            <FactorItem
              label="Email"
              email={emailFactor.email}
              isActive={emailFactor.status === 'ACTIVE'}
              canDelete={numOfActivteFactors > 1}
              onSetup={setupEmailFactor}
              onDelete={deleteEmailFactor}
              onUpdate={updateEmailFactor}
              testIdSuffix="email"
            />
          ) : null}
          {!loading &&
          smsFactor &&
          (smsFactor.supported || smsFactor.enrolled) ? (
            <FactorItem
              label="SMS"
              phoneNumber={smsFactor.phoneNumber}
              isActive={smsFactor.status === 'ACTIVE'}
              canDelete={numOfActivteFactors > 1}
              onSetup={setupSMSFactor}
              onDelete={deleteSMSFactor}
              onUpdate={updateSMSFactor}
              testIdSuffix="sms"
            />
          ) : null}
        </PanelContent>
      </Panel>
      <EmailFactorEnrolModal
        factor={emailFactor}
        isOpen={emailFactorModal.isEnabled}
        onClose={emailFactorModal.disable}
        onEnrol={handleEmailFactorEnrolment}
        onActivate={handleFactorActivation}
      />
      <SMSFactorEnrolModal
        factor={smsFactor}
        isOpen={smsFactorModal.isEnabled}
        onClose={smsFactorModal.disable}
        onEnrol={handleSMSFactorEnrolment}
        onActivate={handleFactorActivation}
      />
      {factorToDelete ? (
        <FactorDeleteModal
          factor={factorToDelete}
          noOtherFactors={numOfActivteFactors === 1}
          isOpen={deleteFactorModal.isEnabled}
          onClose={deleteFactorModal.disable}
          onDelete={performDelete}
        />
      ) : null}
      <EmailFactorUpdateModal
        isOpen={emailFactorUpdateModal.isEnabled}
        onClose={emailFactorUpdateModal.disable}
      />
      <SMSFactorEnrolModal
        factor={smsFactor}
        updateMode={true}
        isOpen={smsFactorUpdateModal.isEnabled}
        onClose={smsFactorUpdateModal.disable}
        onEnrol={handleSMSFactorEnrolment}
        onActivate={handleFactorActivation}
      />
    </div>
  )
}
