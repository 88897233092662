import {
  BaseModalProps,
  Modal,
  ModalBody,
  ModalButtons,
} from './components/Modal'
import { P } from './components/typography'

export const TooManyAddressesAlert = ({ isOpen, onClose }: BaseModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} blur>
    <ModalBody showCloseButton>
      <div className="max-w-xs">
        <P className="mb-8 text-2xl text-center">
          Steady on, you can’t save any more addresses. Delete another one and
          try again.
        </P>
      </div>
    </ModalBody>
    <ModalButtons primaryCTAText="Ok" onPrimaryCTAClick={onClose} />
  </Modal>
)
