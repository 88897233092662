import { useState, useCallback, FormEvent, ChangeEvent } from 'react'

type InputEl = HTMLInputElement | HTMLSelectElement | HTMLFieldSetElement

type Config = {
  validate?: (value: string) => boolean
}

export const useInlineErrors = (config?: Config) => {
  const { validate } = config || {}

  const [invalid, setInvalid] = useState(false)

  const checkValidityOnChange = useCallback(
    (e: ChangeEvent<InputEl>) => {
      if (invalid) {
        setInvalid(false)

        if (validate) {
          setInvalid(validate((e.target as HTMLInputElement).value))
        } else {
          e.target.checkValidity()
        }
      }
    },
    [invalid]
  )

  const onInvalid = (e: FormEvent<InputEl>) => {
    e.preventDefault()
    setInvalid(true)
  }

  return { invalid, onInvalid, checkValidityOnChange }
}
