import React, { ReactNode, useContext } from 'react'
import ReactModal from 'react-modal'

import css from './Modal.module.scss'
import { classes } from '../utils/classes'
import { FlipElement, FlipWrapper } from '../animation/flip'
import { Button } from './buttons/Button'
import { LeftArrow } from './icons/LeftArrow'
import { Loader } from './Loader'

ReactModal.setAppElement('#__next')

export interface BaseModalProps {
  // We could show and hide the modal by rendering or not rendering it, but
  // that approach doesn't work with exit animations sadly
  isOpen: boolean
  onClose: () => void
}

export interface ModalProps extends BaseModalProps {
  animationKey?: string
  children: ReactNode
  blur?: boolean
}

const transitionDuration = 'duration-200'
export const exitAnimationDuration = 200

const flipAnimationId = 'modal'

const ModalOnCloseContext = React.createContext(() => {})

export const Modal = ({
  isOpen,
  onClose,
  animationKey = '',
  blur = false,
  children,
}: ModalProps) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={{
      base: classes(
        'fixed inset-0 flex flex-col justify-center items-center z-10',
        blur ? 'backdrop-filter backdrop-blur-sm' : '',
        // animation styles:
        css.modalOverlay,
        'transition-colors',
        transitionDuration
      ),
      afterOpen: css.afterOpen,
      beforeClose: css.beforeClose,
    }}
    className={{
      base: classes(
        'outline-none max-w-full flex flex-col justify-center items-center w-full md:w-auto h-full md:h-min md:px-0',
        // animation styles:
        css.modal,
        'transition',
        transitionDuration
      ),
      afterOpen: css.afterOpen,
      beforeClose: css.beforeClose,
    }}
    closeTimeoutMS={exitAnimationDuration}
  >
    <ModalOnCloseContext.Provider value={onClose}>
      <div className="w-full shadow-customBlack">
        <FlipWrapper flipKey={animationKey} applyTransformOrigin={false}>
          <FlipElement flipId={flipAnimationId}>
            <div
              className="relative flex flex-col flex-1 overflow-y-auto bg-gray-200 md:rounded origin-top-left max-h-screen md:max-h-3/4-screen"
              data-testid="modal-content"
            >
              <FlipElement inverseFlipId={flipAnimationId} scale>
                {/* div is used by FlipElement */}
                <div>{children}</div>
              </FlipElement>
            </div>
          </FlipElement>
        </FlipWrapper>
      </div>
    </ModalOnCloseContext.Provider>
  </ReactModal>
)

export const ModalBody = ({
  showCloseButton,
  children,
}: {
  showCloseButton?: boolean
  children: ReactNode
}) => {
  const onClose = useContext(ModalOnCloseContext)

  return (
    <div className="pt-12">
      {showCloseButton ? <CloseButton onClick={onClose} /> : null}
      <div className="mx-6 md:mx-16 flex justify-center">{children}</div>
    </div>
  )
}

export const ModalBodyWithTitle = ({
  showCloseButton,
  showBackButton = false,
  onBack = () => {},
  title,
  children,
}: {
  showCloseButton?: boolean
  showBackButton?: boolean
  onBack?: () => void
  title: string
  children: ReactNode
}) => {
  const onClose = useContext(ModalOnCloseContext)

  return (
    <div className="w-full md:w-112">
      <div className="grid grid-cols-[40px,1fr,40px] py-4 border-b border-gray-300 sticky top-0 bg-gray-200 w-full">
        {showBackButton ? <BackButton onClick={onBack} /> : null}
        <h2 className="text-lg font-medium text-center col-start-2 leading-9">
          {title}
        </h2>
        {showCloseButton ? <CloseButtonV2 onClick={onClose} /> : null}
      </div>
      <div className="mx-4 md:mx-16">
        <div className="py-4">{children}</div>
      </div>
    </div>
  )
}

export const ModalBodyAsLoader = ({ loaded }: { loaded: boolean }) => {
  return (
    <ModalBody>
      <div className="mb-12">
        <div className="flex items-center justify-center flex-1">
          <Loader loaded={loaded} />
        </div>
      </div>
    </ModalBody>
  )
}

export interface ModalButtonProps {
  primaryCTAText: string
  onPrimaryCTAClick: () => void
  secondaryCTAText?: string
  onSecondaryCTAClick?: () => void
}

export const ModalButtons = ({
  primaryCTAText = '',
  onPrimaryCTAClick = () => {},
  secondaryCTAText,
  onSecondaryCTAClick,
}: ModalButtonProps) => {
  return (
    <div className="sticky bottom-0 flex w-full px-4 md:px-16 pt-4 pb-6 bg-gray-200 border-t border-gray-300 justify-evenly">
      {secondaryCTAText || onSecondaryCTAClick ? (
        // Two buttons.
        <>
          <div className="mr-2 w-full md:mr-1">
            <Button type="button" secondary onClick={onSecondaryCTAClick}>
              {secondaryCTAText}
            </Button>
          </div>
          <div className="ml-2 w-full md:ml-1">
            <Button type="button" onClick={onPrimaryCTAClick}>
              {primaryCTAText}
            </Button>
          </div>
        </>
      ) : (
        // Single button.
        <div className="w-full">
          <Button type="button" onClick={onPrimaryCTAClick}>
            {primaryCTAText}
          </Button>
        </div>
      )}
    </div>
  )
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    className={classes(
      'absolute w-5 h-5 top-4 right-4 hover:opacity-50 transition-opacity duration-150',
      css.closeIcon
    )}
  >
    <span className="sr-only">Close</span>
  </button>
)

const CloseButtonV2 = ({ onClick }: { onClick: () => void }) => (
  <button type="button" onClick={onClick} className="pb-1/2 mx-auto">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M18 7.209L16.791 6 12 10.791 7.209 6 6 7.209 10.791 12 6 16.791 7.209 18 12 13.209 16.791 18 18 16.791 13.209 12z" />
    </svg>
  </button>
)

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <button type="button" onClick={onClick} className="pb-1/2 mx-auto">
    <LeftArrow color="black" />
  </button>
)
