import React, {
  MouseEvent,
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  Ref,
} from 'react'
import { classes } from '../../utils/classes'
import { Countries, findByCode, findByDialCode, Country } from './Countries'
import css from './PhoneInput.module.scss'
import parsePhoneNumber from 'libphonenumber-js'
import { useInlineErrors } from '../../utils/useInlineErrors'
import { ErrorMessage } from './ErrorMessage'

type PhoneInputProps = {
  name: string
  label: string
  defaultCountryCode: string
  defaultValue: string
  errorMessage?: string
  invalid?: boolean
  onChange?: (value: any) => void
}

export const PhoneInput = ({
  name,
  label,
  defaultCountryCode,
  defaultValue,
  errorMessage,
  invalid,
  onChange,
}: PhoneInputProps) => {
  const { onInvalid } = useInlineErrors()

  const [showDropdown, setShowDropdown] = useState(false)

  const [number, setNumber] = useState<string>(
    (() => {
      if (!defaultValue) return ''

      let parsed = parsePhoneNumber(defaultValue)
      if (!parsed) {
        return ''
      }

      return parsed.nationalNumber.toString()
    })()
  )

  const [country, setCountry] = useState<Country>(
    getCountryFromNumber(defaultValue) || findByCode(defaultCountryCode)!
  )

  const changeCountry = (country: Country) => {
    setCountry(country)
    const value = `(${country.dial_code}) ${number}`
    onChange?.(value)
  }

  const dropdownRef = useRef<HTMLUListElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    let dialCode = value
      .substring(value.indexOf('('), value.indexOf(')') + 1)
      .trim()
    let newNumber = value.replace(dialCode, '').replace(/\s+/, '')

    let regex = /^\d+$/
    if (!regex.test(newNumber) && newNumber.length > 0) {
      e.preventDefault()
      return
    }

    let parsed = parsePhoneNumber(dialCode + newNumber)
    if (parsed) {
      newNumber = parsed.nationalNumber.toString()
    }

    setNumber(newNumber)

    if (onChange) {
      onChange(value)
    }
  }

  const handleFlagDropdownClick = () => {
    setShowDropdown(!showDropdown)
  }

  useEffect(() => {
    if (!country && (!number || number.length === 0)) {
      setCountry(findByCode(defaultCountryCode)!)
    }
  }, [country, number, defaultCountryCode])

  useEffect(() => {
    if (!showDropdown) return

    if (dropdownRef.current) {
      dropdownRef.current.scrollIntoView()
    }
  }, [showDropdown, dropdownRef])

  return (
    <>
      <div
        className={classes(
          'border',
          'border-gray-600',
          'bg-white',
          'h-11',
          showDropdown ? 'mb-32' : '',
          css.reactTelInput
        )}
      >
        <input
          name={name}
          aria-label={label}
          className={classes(css.formControl, 'bg-white')}
          value={`(${country.dial_code}) ${number}`}
          onChange={handleChange}
          type="tel"
          onInvalid={onInvalid}
        />

        <div className={classes(css.flagDropdown)}>
          <div
            id={'open-dropdown-button'}
            onClick={handleFlagDropdownClick}
            className={classes(css.selectedFlag)}
            title={`${country.name}: + ${country.dial_code}`}
            tabIndex={0}
            role="button"
          >
            <div className={css.select}>
              <p className={css.flag}>{country.flag}</p>
            </div>
          </div>
        </div>
        {showDropdown ? (
          <CountryDropdownList
            dropdownRef={dropdownRef}
            activeCode={country.code}
            setShowDropdown={setShowDropdown}
            setCountry={changeCountry}
          />
        ) : null}
      </div>
      {errorMessage && invalid ? (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      ) : null}
    </>
  )
}

type CountryDropdownListProps = {
  activeCode: string
  setShowDropdown: (v: boolean) => void
  setCountry: (v: Country) => void
  dropdownRef: Ref<HTMLUListElement>
}

const CountryDropdownList = ({
  activeCode,
  setShowDropdown,
  setCountry,
  dropdownRef,
}: CountryDropdownListProps) => {
  const handleFlagItemClick = (e: MouseEvent, country: Country) => {
    e.preventDefault()
    setShowDropdown(false)
    setCountry(country)
  }

  const items = Countries.map((country, i) => {
    let classNames = [css.country]

    if (country.code === activeCode) {
      classNames.push(...[css.active, css.highlight])
    }

    return (
      <React.Fragment key={i}>
        <li
          className={classes(...classNames)}
          onClick={(e) => handleFlagItemClick(e, country)}
          role="option"
          id={'dropdown-country-' + country.code}
        >
          <span>{country.flag} </span>
          <span className={css.countryName}>{country.name}</span>
          <span className={css.dialCode}>{country.dial_code}</span>
        </li>
        {i == 1 ? <li className={css.divider} /> : null}
      </React.Fragment>
    )
  })

  return (
    <ul ref={dropdownRef} className={classes(css.countryList)} role="listbox">
      {items}
    </ul>
  )
}

function getCountryFromNumber(number: string): Country | null {
  if (number && number.length > 0 && number.startsWith('+')) {
    const dialCode = parsePhoneNumber(number)?.countryCallingCode

    if (dialCode && dialCode?.length > 0) {
      return findByDialCode('+' + dialCode.toString())
    }
  }

  return null
}
