import React from 'react'
import { classes } from '../../utils/classes'

const COMPONENT_CLASS_NAME = 'divider'

/**
 * Divider line used in the main menu and footer components
 *
 * @function
 * @param       {[string]} className additional className which can be set in the data.
 * @constructor
 */

type DividerProps = {
  className: string
}

export const Divider = ({ className = '' }: DividerProps) => {
  return (
    <div
      role="separator"
      className={classes(className, COMPONENT_CLASS_NAME)}
    />
  )
}
