import React from 'react'
/**
 * The Nando’s menu logo. The logo itself is an SVG in css. This could potentially be replaced with the existing Link component.
 *
 * @function
 * @param       {String} href              Should contain a link to the href target of the initial anchor.
 * @param       {string} text              The text of the link.
 * @param       {string} title             A slightly longer descriptive text for the title attribute of the link.
 * @param       {string} className         An optional class to add to the existing component class attribute.
 * @constructor
 */

export type LogoLinkProps = {
  href: string
  text: string
  title: string
  className: string
}
export const LogoLink = ({
  href = '',
  text = '',
  title = '',
  className = '',
}: LogoLinkProps) => {
  return (
    <h1 className={className}>
      <a href={href} title={title}>
        {text}
      </a>
    </h1>
  )
}
